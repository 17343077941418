import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import InterfaceBlocker from "../typescript/interfaceBlocker"
import InterfaceBlockers from "../typescript/interfaceBlockers"
import { getAssets, addAsset, removeAsset, updateAsset } from "./services/assetsService"

interface InterfaceAssetData {
  id: string
  biomeType: number
  type: number
  colorType?: number
  name: string
  blockerState?: number
  image: string | undefined
  imageShow: string | undefined
  imageFile: File | undefined
  imageName: string
}

interface InterfaceRemoveAssetData {
  id: string
}

export const getBlockers = createAsyncThunk("blockers/getBlockers", async () =>{
  try{
    return await getAssets("blockers")
  }catch(err:any){
    return err.response.data.message
  }
})

export const newBlocker = createAsyncThunk("blockers/newBlocker", async (assetData:InterfaceAssetData) =>{
  try{
    return await addAsset("blockers", assetData)
  }catch(err:any){
    return "Adding new blocker process successfully failed."
  }
})

export const updateBlocker = createAsyncThunk("blockers/updateBlocker", async (assetData:InterfaceAssetData) =>{
  try{
    return await updateAsset("blockers", assetData)
  }catch(err:any){
    return "Adding new blocker process successfully failed."
  }
})

export const removeBlocker = createAsyncThunk("blockers/removeBlocker", async (assetData:InterfaceRemoveAssetData) =>{
  try{
    return await removeAsset("blockers", assetData)
  }catch(err:any){
    return "Removing blocker process successfully failed.."
  }
})

const initialState:InterfaceBlockers = {
  blockers: [

  ],
  requestStatus: {
    loading: false,
    saveButtonLoading: false,
    removeButtonLoading: false,
    error: false,
    message: "",
    redirect: ""
  }
}

const blockersSlice = createSlice({
  name: "blockers",
  initialState,
  reducers: {
    resetBlockerRequestStatus: (state) => {
      state.requestStatus = initialState.requestStatus
    }
  },
  extraReducers: (builder) => {
    //get all blockers
    builder.addCase(getBlockers.pending, (state)=>{
      state.requestStatus.loading = true
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = ""
    })
    builder.addCase(getBlockers.fulfilled, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = ""

      state.blockers = action.payload.data.sort((a:InterfaceBlocker,b:InterfaceBlocker)=>(a.type > b.type || a.blockerState < b.blockerState || a.colorType && b.colorType && a.colorType > b.colorType) ? 1 : -1)
    })
    builder.addCase(getBlockers.rejected, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = true
      state.requestStatus.message = action.payload
    })

    //new blocker
    builder.addCase(newBlocker.pending, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = true
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = ""
    })
    builder.addCase(newBlocker.fulfilled, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = "Blocker created successfully."

      const currentAssets = [...state.blockers]
      
      currentAssets.push({
        id: action.payload.data.item.id,
        biomeType: action.payload.data.item.biomeType,
        type: action.payload.data.item.type,
        colorType: action.payload.data.item.colorType,
        blockerState: action.payload.data.item.blockerState,
        name: action.payload.data.item.name,
        image: action.payload.data.item.image,
      })

      state.blockers = currentAssets.sort((a:InterfaceBlocker,b:InterfaceBlocker)=>(a.type > b.type || a.blockerState < b.blockerState || a.colorType && b.colorType && a.colorType > b.colorType) ? 1 : -1)
    })
    builder.addCase(newBlocker.rejected, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = true
      state.requestStatus.message = "Blocker couldn't create for some reason."
    })

    //update blocker
    builder.addCase(updateBlocker.pending, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = true
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = ""
    })
    builder.addCase(updateBlocker.fulfilled, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = "Blocker updated successfully."

      const currentAssets = [...state.blockers]
      
      currentAssets.map((asset)=>{
        if(asset.id === action.payload.data.item.id){
          asset.biomeType = action.payload.data.item.biomeType,
          asset.type = action.payload.data.item.type,
          asset.colorType = action.payload.data.item.colorType,
          asset.blockerState = action.payload.data.item.blockerState,
          asset.name = action.payload.data.item.name,
          asset.image = action.payload.data.item.image
        }
      })

      state.blockers = currentAssets.sort((a:InterfaceBlocker,b:InterfaceBlocker)=>(a.type > b.type || a.blockerState < b.blockerState || a.colorType && b.colorType && a.colorType > b.colorType) ? 1 : -1)
    })
    builder.addCase(updateBlocker.rejected, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = true
      state.requestStatus.message = "Blocker couldn't update for some reason."
    })

    //remove blocker
    builder.addCase(removeBlocker.pending, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = true
      state.requestStatus.error = false
      state.requestStatus.message = ""
    })
    builder.addCase(removeBlocker.fulfilled, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = "Blocker removed successfully."

      const currentAssets = [...state.blockers]

      currentAssets.map((asset, i)=>{
        if(asset.id === action.payload.data.item.id){
          currentAssets.splice(i,1)
        }
      })

      state.blockers = currentAssets.sort((a:InterfaceBlocker,b:InterfaceBlocker)=>(a.type > b.type || a.blockerState < b.blockerState || a.colorType && b.colorType && a.colorType > b.colorType) ? 1 : -1)
    })
    builder.addCase(removeBlocker.rejected, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = true
      state.requestStatus.message = "Blocker couldn't remove for some reason."
    })
  },
})

export const { resetBlockerRequestStatus } = blockersSlice.actions
export default blockersSlice.reducer