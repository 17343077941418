import biomesList from "../../lists/LevelEditor/biomeTypeList"
import InterfaceBiome from "../../../typescript/interfaceBiome"

class BiomeTypes {
  
  getAll(){
    return biomesList
  }

  getOne(type:number){

    let data:InterfaceBiome | any
    
    if((typeof type === "number" && type === 0) || type){
      biomesList.map((item)=>{
        if(Number(item.type) === Number(type)){
          data = item
        }
      })
    }
    
    return data
  }

}

export default BiomeTypes