import React, { FC } from "react"
import { Box, Checkbox, Text, SystemStyleObject } from "@chakra-ui/react"

interface Props {
  name: string
  margin?: string
  color?: string,
  sx?: SystemStyleObject
  sxDiv?: SystemStyleObject
  label?: string[]
  topLabel?: string
  biggerLabel?: boolean
  value: boolean,
  return?: (data:{name:string, value:boolean}) => void
}

const InputCheckbox:FC<Props> = (props) => {
  
  function onChangeInput(value:any){

    const data = {
      name: props.name,
      value: value.target.checked
    }
    props.return!(data)
  }
  
  return (
    <Box margin={props.margin ? props.margin : "0 0 10px 0"} w="fit-content" sx={props.sxDiv}>

      {
        props.topLabel && !props.biggerLabel ? 
          <Text fontSize="12px" fontWeight="600" marginBottom="7.5px">{props.topLabel}</Text> 
          : props.topLabel && props.biggerLabel && <Text fontSize="16px" fontWeight="600" marginBottom="7.5px" color="#607B96" userSelect="none">{props.topLabel}</Text>
      } 
      
      <Checkbox dataset-name={props.name} colorScheme="tranparent" borderColor={props.color ? props.color : "#fff"} size="lg" outline="none" backgroundColor="transparent" iconColor={props.color ? props.color : "#fff"} color={props.color ? props.color : "#fff"} _active={{transform: "scale(.95)"}} transition="200ms" sx={{"& span": {m:0}, ...props.sx}} checked={props.value} isChecked={props.value} onChange={(val)=>onChangeInput(val)}>
        <Box display="flex">
          {props.label?.map((labelItem,i)=>
            <Text key={i} marginLeft="5px" color={i === 1 ? props.color : "#fff"} userSelect="none">{labelItem}</Text>
          )}
        </Box>
      </Checkbox>
    </Box>
  )
}

export default InputCheckbox