import { useSelector, useDispatch } from "react-redux"
import { changeClipboard } from "../../../../redux/editorSlice"
import InterfaceAllSlices from "../../../../typescript/interfaceAllSlices"

import Spawner from "../../../../classes/getFromRedux/LevelEditor/Spawner"

import {Box} from "@chakra-ui/react"

import InputSelectItem from "../../../Inputs/InputSelectItem"

const PlayableItems = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const dispatch = useDispatch()

  const spawner = new Spawner()
  
  return (
    <Box pt="100px" mx="auto" w="fit-content" display="flex" flexDir="column">
      <InputSelectItem name="" placeholder="Color Stones" dropdownItemsGrid={3} dropdownItems={spawner.getColorStones()} 
        return={(dataInput)=>dispatch(changeClipboard({target: 1, ...(dataInput.value.type && {type: dataInput.value.type}), ...dataInput.value.yieldertype && {yielderType: dataInput.value.yieldertype}}))}
      />
      <InputSelectItem name="" placeholder="Power Stones" dropdownItemsGrid={3} dropdownItems={spawner.getPowerStones()} 
        return={(dataInput)=>dispatch(changeClipboard({target: 1, ...(dataInput.value.type && {type: dataInput.value.type}), ...dataInput.value.yieldertype && {yielderType: dataInput.value.yieldertype}}))}
      />
      <InputSelectItem name="" placeholder="Animals" dropdownItemsGrid={3} dropdownItems={spawner.getByBiome(levelStates.biomeType)} 
        return={(dataInput)=>dispatch(changeClipboard({target: 1, ...(dataInput.value.type && {type: dataInput.value.type}), ...dataInput.value.yieldertype && {yielderType: dataInput.value.yieldertype}}))}
      />
    </Box>
  )
}

export default PlayableItems