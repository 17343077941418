import InterfaceSpawner from "../../../typescript/interfaceSpawner"

function getSpawners(){
  const allStates = localStorage.getItem("persist:root")
  
  if(allStates){ 
    const parsedAllStates = JSON.parse(allStates)
    const parsedSpawners = JSON.parse(parsedAllStates.spawners)
    const spawners = parsedSpawners.spawners
    return spawners
  } else{
    return null
  }
}

class Spawner{

  getAll(){
    return getSpawners()
  }

  getById(id:string):InterfaceSpawner{
    
    let data:InterfaceSpawner[] | any
    
    getSpawners()?.map((item:InterfaceSpawner)=>{

      if(id === item.id){
        data = item
      }

    })

    return data
  }

  getOne(type:number, yielderType?:number):InterfaceSpawner{
    
    let data:InterfaceSpawner[] | any
    
    getSpawners()?.map((item:InterfaceSpawner)=>{

      if(type === item.type && (typeof yielderType === "number" && yielderType === item.yielderType || !yielderType && type !== 2048)){
        data = item
      }

    })

    return data ? data : {image:"notfound"}
  }

  getColorStones():InterfaceSpawner[]{
    
    const data:InterfaceSpawner[] | any = []

    getSpawners()?.map((item:InterfaceSpawner)=>{
      if(item.type === 1 || item.type === 2 || item.type === 4 || item.type === 8 || item.type === 16 || item.type === 32){
        data.push(item)
      }
    })

    return data
  }

  getPowerStones():InterfaceSpawner[]{
    
    const data:InterfaceSpawner[] | any = []

    getSpawners()?.map((item:InterfaceSpawner)=>{
      if(item.type === 64 || item.type === 128 || item.type === 256 || item.type === 512 || item.type === 1024){
        data.push(item)
      }
    })

    return data
  }

  getByBiome(biome:number):InterfaceSpawner[]{
    
    const data:InterfaceSpawner[] | any = []

    getSpawners()?.map((item:InterfaceSpawner)=>{
      if(item.biomeType === biome){
        data.push(item)
      }
    })

    return data
  }

}

export default Spawner