import blockerTypesList from "../../lists/LevelEditor/blockerTypesList"
import blockerColorTypesList from "../../lists/LevelEditor/blockerColorTypesList"
import blockerRainbowTypesList from "../../lists/LevelEditor/blockerRainbowTypesList"
import {InterfaceBlockerType} from "../../../typescript/interfaceBlockerType"

class BlockerTypes {

  getAll(){
    return blockerTypesList
  }

  getOne(type:number){

    let data:InterfaceBlockerType[] | any
    
    if((typeof type === "number" && type === 0) || type){
      blockerTypesList.map((item)=>{
        if(item.type === type){
          data = item
        }
      })
    }

    return data
  }

  getByBiome(biome:number){
    
    const data:InterfaceBlockerType[] | any = []
    
    if((typeof biome === "number" && biome === 0) || biome){
      blockerTypesList.map((item)=>{
        if(item.biome === biome){
          data.push(item)
        }
      })
    }
    
    return data
  }

  getByOutOfBiome(biome:number){
    
    const data:InterfaceBlockerType[] | any = []
    
    if((typeof biome === "number" && biome === 0) || biome){
      blockerTypesList.map((item)=>{
        if(item.biome !== biome){
          data.push(item)
        }
      })
    }
    
    return data
  }

  getAllColors(){
    return blockerColorTypesList
  }

  getOneColor(type:number){

    let data:InterfaceBlockerType[] | any
    
    if((typeof type === "number" && type === 0) || type){
      blockerColorTypesList.map((item)=>{
        if(item.type === type){
          data = item
        }
      })
    }

    return data
  }

  getAllRainbows(){
    return blockerRainbowTypesList
  }

  getOneRainbow(type:number){

    let data:InterfaceBlockerType[] | any
    
    if((typeof type === "number" && type === 0) || type){
      blockerRainbowTypesList.map((item)=>{
        if(item.type === type){
          data = item
        }
      })
    }

    return data
  }

}

export default BlockerTypes