import axios from "../../axios"
import axiosOut from "axios"

interface InterfaceAssetData {
  id: string
  biomeType: number
  type: number
  yielderType?: number
  colorType?: number
  name: string
  blockerState?: number
  image: string | undefined
  imageShow: string | undefined
  imageFile: File | undefined
  imageName: string
}

interface InterfaceRemoveAssetData {
  id: string
}

const getAssets = async (purpose:string) => {
  const response = await axios.get(purpose)
  return response
}

const addAsset = async (purpose:string, newAssetData:InterfaceAssetData) => {
    
  const body = {
    biomeType: newAssetData.biomeType,
    type: newAssetData.type,
    ...(newAssetData.yielderType && {yielderType: newAssetData.yielderType}),
    ...(newAssetData.colorType && {colorType: newAssetData.colorType}),
    name: newAssetData.name,
    ...(newAssetData.blockerState && {blockerState: newAssetData.blockerState}),
    image: newAssetData.image,
  }
  
  const response = await axios.post(`/${purpose}`, body)
  
  if(response.data.signedUrl){

    if(newAssetData.imageShow){

      const imageFileBlob = newAssetData.imageFile?.slice(0, newAssetData.imageFile.size, "image/png")
      const newImageFile = new File([imageFileBlob ? imageFileBlob : ""], newAssetData.imageName, {type: "image/png"})
      
      const formData = new FormData()
      formData.append("file", newAssetData.imageFile ? newImageFile : "no-image", newAssetData.imageName)
      
      axiosOut({
        url: response.data.signedUrl,
        method: "put", 
        headers: { 
          "Content-Type": newImageFile?.type
        },
        data : newImageFile
      })

    }
    
  }
  
  return response

}

const updateAsset = async (purpose:string, updateAssetData:InterfaceAssetData) => {

  const body = {
    id: updateAssetData.id,
    biomeType: updateAssetData.biomeType,
    type: updateAssetData.type,
    ...(updateAssetData.yielderType && {yielderType: updateAssetData.yielderType}),
    ...(updateAssetData.colorType && {colorType: updateAssetData.colorType}),
    name: updateAssetData.name,
    ...(updateAssetData.blockerState && {blockerState: updateAssetData.blockerState}),
    image: updateAssetData.image,
  }
  
  const response = await axios.put(`/${purpose}`, body)
  
  if(response.data.signedUrl){

    if(updateAssetData.imageShow){

      const imageFileBlob = updateAssetData.imageFile?.slice(0, updateAssetData.imageFile.size, "image/png")
      const newImageFile = new File([imageFileBlob ? imageFileBlob : ""], updateAssetData.imageName, {type: "image/png"})
      
      const formData = new FormData()
      formData.append("file", updateAssetData.imageFile ? newImageFile : "no-image", updateAssetData.imageName)
      
      axiosOut({
        url: response.data.signedUrl,
        method: "put", 
        headers: { 
          "Content-Type": newImageFile?.type
        },
        data : newImageFile
      })

    }
    
  }

  return response
  
}

const removeAsset = async (purpose:string, removeAssetData:InterfaceRemoveAssetData) => {

  const body = {
    id: removeAssetData.id
  }
  
  const response = await axios.delete(`/${purpose}`, {data: body})

  response.data = {
    item: body
  }
  
  return response
  
}

export { getAssets, addAsset, updateAsset, removeAsset }