import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit"
import InterfaceUserSlice from "../typescript/interfaceUserSlice"
import { login } from "./services/authService"

interface UserData{
  email: string
  password: string
}

export const loginAuth = createAsyncThunk("users/loginAuth", async (userData:UserData, thunkAPI)=>{
  try{
    return await login(userData)
  }catch(err:any){
    return thunkAPI.rejectWithValue(err.response.data.message)
  }
})

const initialState:InterfaceUserSlice = {
  id: "",
  name: "",
  email: "",
  role: "",
  active: false,
  token: "",
  exp: 0,
  requestStatus: {
    loading: false,
    error: false,
    message: ""
  }
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAuth.pending, (state)=>{
        state.requestStatus.loading = true
        state.requestStatus.error = false
        state.requestStatus.message = ""
      })
      .addCase(loginAuth.fulfilled, (state, action:PayloadAction<any>)=>{
        state.requestStatus.loading = false
        state.requestStatus.error = false
        state.requestStatus.message = ""
        
        if(action.payload){ 
          state.id = action.payload.id
          state.name = action.payload.name
          state.email = action.payload.email
          state.role = action.payload.role 
          state.token = action.payload.token
          state.exp = action.payload.exp
        }
      })
      .addCase(loginAuth.rejected, (state, action:PayloadAction<any>)=>{
        state.requestStatus.loading = false
        state.requestStatus.error = true
        state.requestStatus.message = action.payload

        state = initialState
      })
  }
})

export const {logout} = userSlice.actions
export default userSlice.reducer