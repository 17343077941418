import spawnerTypesList from "../../lists/LevelEditor/spawnerTypeList"
import spawnerYieldersList from "../../lists/LevelEditor/spawnerYielderTypeList"
import { InterfaceSpawnerType } from "../../../typescript/interfaceSpawnerType"

class SpawnerTypes {

  getAll(){
    return spawnerTypesList
  }

  getOne(type:number){

    let data:InterfaceSpawnerType[] | any
    
    if((typeof type === "number" && type === 0) || type){
      spawnerTypesList.map((item)=>{
        if(item.type === type){
          data = item
        }
      })
    }

    return data
  }

  getByBiome(biome:number){

    const data:InterfaceSpawnerType[] | any = []
    
    if((typeof biome === "number" && biome === 0) || biome){
      spawnerTypesList.map((item)=>{
        if(item.biome === biome){
          data.push(item)
        }
      })
    }

    return data
  }

  getByOutOfBiome(biome:number){
    
    const data:InterfaceSpawnerType[] | any = []
    
    if((typeof biome === "number" && biome === 0) || biome){
      spawnerTypesList.map((item)=>{
        if(item.biome !== biome){
          data.push(item)
        }
      })
    }
    
    return data
  }

  getAllYielders(){
    
    return spawnerYieldersList
    
  }

  getOneYielder(type:number){

    let data:InterfaceSpawnerType[] | any

    if((typeof type === "number" && type === 0) || type){
      spawnerYieldersList.map((item)=>{
        if(item.type === type){
          data = item
        }
      })
    }

    return data
    
  }

  getYieldersByBiome(biome:number){

    const data:InterfaceSpawnerType[] | any = []
    
    if((typeof biome === "number" && biome === 0) || biome){
      spawnerYieldersList.map((item)=>{
        if(item.biome === biome){
          data.push(item)
        }
      })
    }

    return data
  }

}

export default SpawnerTypes