import { useSelector, useDispatch } from "react-redux"
import { changeLevelNumber } from "../../../../redux/levelSlice"
import InterfaceAllSlices from "../../../../typescript/interfaceAllSlices"

import {Box} from "@chakra-ui/react"
import InputNumber from "../../../Inputs/InputNumber"

const GeneralLevelSettings = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  function onChangeLevelNumber(val:number){
    const data = {
      levelNumber: Number(val),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeLevelNumber(data))
  }
  
  return (
    <Box pt="100px">
      <InputNumber name="" label="Level Number" sxDiv={{m: "auto"}} min={1} value={levelStates.levelNumber} return={(dataInput)=>onChangeLevelNumber(dataInput.value)}/>
    </Box>
  )
}

export default GeneralLevelSettings