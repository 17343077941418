import React, {FC} from "react"
import Lottie from "lottie-react"
import {Box, Text} from "@chakra-ui/react"
import {motion} from "framer-motion"

import { useDispatch } from "react-redux"
import { collapsePopupDelete } from "../../redux/popupManagerSlice"

import InputButton from "../Inputs/InputButton"

import errorAnimation from "../../assets/animations/error.json"

const PopupDeleteValidation:FC = () => {  

  const dispatch = useDispatch()
  
  return (
    <Box>
      <Box as={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} backgroundColor="#000000aa" position="fixed" top="0" left="0" width="100vw" height="100vh" zIndex="11" onClick={()=>dispatch(collapsePopupDelete(false))}/>
      <Box as={motion.div} initial={{transform: "translate(-50%,-50%) scale(0.1)"}} animate={{transform: "translate(-50%,-50%) scale(1)"}} position="fixed" top="50%" left="50%" transform="translate(-50%,-50%)" zIndex="12" w="515px" h="315px" border="2px solid #fff" borderRadius="8px" p="30px 0" bg="#000">

        <Lottie 
          animationData={errorAnimation} 
          loop={false}
          style={{
            width: "100px",
            margin: "0 auto 20px auto"
          }}
        />

        <Text textTransform="uppercase" fontSize="20px" fontWeight="600" textAlign="center">ARE YOU SURE THAT YOU WANT TO DELETE IT?</Text>
        <Text fontSize="16px" fontWeight="400" textAlign="center" mb="25px">You won't be able to revert.</Text>

        <Box m="0 auto" display="flex" justifyContent="center">
          <InputButton label='No' bg='#4BB34B' width="110px" margin='0 10px 0 0' onClick={() => dispatch(collapsePopupDelete(false))}/>
          <InputButton label='Yes' bg='#E64646' width="110px" onClick={()=>console.log("remove")}/>
        </Box>

      </Box>
    </Box>
  )
}

export default PopupDeleteValidation