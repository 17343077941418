import { FC, useState, useEffect } from "react"
import axios from "../../axios"
import Moment from "react-moment"

import { useDispatch } from "react-redux"
import { collapsePopupNewLevel } from "../../redux/popupManagerSlice"
import { InterfaceNotification } from "../../typescript/interfaceNotification"

import {Box, Heading, Text} from "@chakra-ui/react"
import { motion } from "framer-motion"
import { RotatingLines as LoaderSpinner } from "react-loader-spinner"

import NotificationCard from "../../Components/NotificationCard"
import InputButton from "../../Components/Inputs/InputButton"

const Home:FC = () => {

  const dispatch = useDispatch()

  const [lastActivity, setLastActivity] = useState<InterfaceNotification>()
  const [loading, setLoading] = useState(false)

  function getLastActivity(){
    setLoading(true)
    axios.get("/levels/activities?limit=1").then((res)=>{            
      setLastActivity(res.data[0])
      setLoading(false)
    }).catch((err)=>{
      console.log(err)
      setLoading(false)
    })
  }

  useEffect(()=>{
    getLastActivity()
  },[])
  
  return (
    <Box width="670px" m="90px auto 0 auto">
      <Heading as="h3" color="#ddd"/* "#3E3E3E" */ fontSize="12px" fontWeight="400" marginBottom="5px">
        <Moment format="DD.MM.YYYY dddd">{lastActivity?.timestamp}</Moment>
      </Heading>
      <Heading as="h1" fontSize="20px" fontWeight="600" marginBottom="20px">Last Activity: {lastActivity?.message}</Heading>
      {
        lastActivity ? 
          <Box 
            as={motion.div}
            initial={{y: 20, opacity: 0}}
            animate={{y: 0, opacity: 1}}
          >
            <NotificationCard levelData={lastActivity} showLevel showButtonEdit showBiome showCreatedBy showDescription showMessage/>
          </Box>
          : loading ?
            <Box m="auto" w="fit-content">
              <LoaderSpinner width="25" strokeColor="#fff"/>
            </Box>
            : 
            <Text>No Data</Text>
      }
      <InputButton label='New Level' margin='315px auto 0 auto' onClick={()=>dispatch(collapsePopupNewLevel(true))}/>
    </Box>
  )
}

export default Home