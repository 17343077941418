import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"

import { Provider as ReduxProvider } from "react-redux"
import ReduxStore, { persistor } from "./redux/store"
import { PersistGate } from "redux-persist/integration/react"

import { ChakraProvider } from "@chakra-ui/react"
import theme from "./chakra/theme"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <ReduxProvider store={ReduxStore}>
      <PersistGate persistor={persistor}>
        <ChakraProvider theme={theme}>
          <App/>
        </ChakraProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>
)