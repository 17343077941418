import { FC } from "react"

import { useSelector } from "react-redux"

import { Box, Heading } from "@chakra-ui/react"
import {motion} from "framer-motion"

//Right Sidebar Elements
import GeneralLevelSettings from "./LevelEditor/RightSidebarElements/GeneralLevelSettings"
import Notifications from "./LevelEditor/RightSidebarElements/Notifications"
import DescriptionAndComments from "./LevelEditor/RightSidebarElements/DescriptionAndComments"
import GridSizeAndPlayableFields from "./LevelEditor/RightSidebarElements/GridSizeAndPlayableFields"
import Objectives from "./LevelEditor/RightSidebarElements/Objectives"
import RandomStoneList from "./LevelEditor/RightSidebarElements/RandomStoneList"
import Rewards from "./LevelEditor/RightSidebarElements/Rewards"
import PlayableItems from "./LevelEditor/RightSidebarElements/PlayableItems"
import Blockers from "./LevelEditor/RightSidebarElements/Blockers"
import Spawners from "./LevelEditor/RightSidebarElements/Spawners"

const RightPart:FC = () => {

  const editorStates = useSelector((state:any) => state.editor)
  
  return (
    <Box height="calc(100vh - 60px)" w="670px" borderLeft="1px solid #1E2D3D" borderRight="1px solid #1E2D3D">
      <Box padding="16px 24px" borderBottom="1px solid #1E2D3D">
        <Box as={motion.div} key={editorStates.content} initial={{opacity: 0, translateX: 20}} animate={{opacity: 1, translateX: 0}}>
          <Heading as={motion.h2} key={editorStates.sidebar} 
            initial={{x: 20}}
            animate={{x: 0}}
            fontSize="16px" fontWeight="600" userSelect="none"
          >{editorStates.sidebar}</Heading>
        </Box>
      </Box>

      <Box p="20px 0" h="calc(100% - 53.5px)" overflowY="auto">
        
        <Box key={editorStates.sidebar} as={motion.div} initial={{opacity: 0, translateY: 20}} animate={{opacity: 1, translateY: 0}} h="100%" position="relative">
          {editorStates.sidebar === "General Level Settings" && <GeneralLevelSettings/>}
          {editorStates.sidebar === "Notifications" && <Notifications/>}
          {editorStates.sidebar === "Description & Comments" && <DescriptionAndComments/>}
          {editorStates.sidebar === "Grid Size & Playable Fields" && <GridSizeAndPlayableFields/>}
          {editorStates.sidebar === "Objectives" && <Objectives/>}
          {editorStates.sidebar === "Random Stone List" && <RandomStoneList/>}
          {editorStates.sidebar === "Rewards" && <Rewards/>}
          {editorStates.sidebar === "Playable Items" && <PlayableItems/>}
          {editorStates.sidebar === "Blockers" && <Blockers/>}
          {editorStates.sidebar === "Spawners" && <Spawners/>}
        </Box>
        
      </Box>
    </Box>
  )
}

export default RightPart