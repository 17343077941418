import {useEffect, useState} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "../../axios"

import { useAppSelector, useAppDispatch } from "../../redux/hooks/useTypedSelectors"
import { getSpawners, resetSpawnerRequestStatus } from "../../redux/spawnersSlice"
import { getBlockers, resetBlockerRequestStatus } from "../../redux/blockersSlice"
import InterfaceAllSlices from "../../typescript/interfaceAllSlices"
import InterfaceSpawner from "../../typescript/interfaceSpawner"
import InterfaceBlocker from "../../typescript/interfaceBlocker"

import BiomeTypes from "../../classes/getTypes/LevelEditor/biomeTypes"
import BlockerTypes from "../../classes/getTypes/LevelEditor/blockerTypes"
import SpawnerTypes from "../../classes/getTypes/LevelEditor/spawnerTypes"

import { Box, Heading, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, Icon, Image } from "@chakra-ui/react"
import {BsChevronRight as RightIcon} from "react-icons/bs"
import {RotatingLines as LoaderSpinner} from "react-loader-spinner"
import { motion } from "framer-motion"

import InputButtonIcon from "../../Components/Inputs/InputButtonIcon"

const Assets = () => {

  const location = useLocation()
  const navigate = useNavigate()

  const page = location.pathname.split("/")[2] //blockers - spawners
  const userStates = useAppSelector((state:InterfaceAllSlices)=>state.user)
  const assetsStates = useAppSelector((state:InterfaceAllSlices)=> page === "blockers" ? state.blockers.blockers : state.spawners.spawners)
  const assetsRequestStatusStates = useAppSelector((state:InterfaceAllSlices)=> page === "blockers" ? state.blockers.requestStatus : state.spawners.requestStatus)
  const dispatch = useAppDispatch()

  const biomeTypes = new BiomeTypes().getAll()
  const assetTypes = page === "blockers" ? new BlockerTypes().getAll() : new SpawnerTypes().getAll()
  const [assets, setAssets] = useState<InterfaceBlocker[] | InterfaceSpawner[] | any[]>([])

  function refreshAssets(){
    if(page === "blockers"){
      dispatch(getBlockers())
    } else if (page === "spawners"){
      dispatch(getSpawners())
    }
  }

  function resetAssets(){
    axios.delete(`${page}/delete-all`).then(()=>{
      refreshAssets()
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    setAssets(assetsStates)
  },[assetsStates])


  useEffect(()=>{
    if(page === "blockers"){
      dispatch(resetBlockerRequestStatus())
    } else if (page === "spawners"){
      dispatch(resetSpawnerRequestStatus())
    }
  },[assetsRequestStatusStates])
  
  return (
    <Box mt="90px" mx="auto" w="1000px">
      <Box display="flex" justifyContent="end" mb="20px">
        {/* <InputButtonIcon icon="remove" size="md" sx={{mr:"10px"}} onClick={()=>resetAssets()}/> */}
        <InputButtonIcon icon="refresh" size="md" onClick={()=>refreshAssets()}/>
      </Box>
      {
        biomeTypes.map((biomeType, i)=>
          <Box key={i} mb="20px">

            <Box display="flex" alignItems="center" h="32px">
              <Heading fontWeight="600" fontSize="16px">{biomeType.name}</Heading>
              {assetsRequestStatusStates.loading && <Box as={motion.div} initial={{scale: 0, x: 0}} animate={{scale: 1, x: 20}}><LoaderSpinner width="25" strokeColor="#fff"/></Box>}
              {userStates.role !== "levelDesigner" && <InputButtonIcon icon="add" size="md" margin="0 0 0 auto" onClick={()=>navigate(`/level-editor/${page}/new-${page.slice(0,-1)}`, {state: {selectedBiome: biomeType.type}})}/>}
            </Box>

            <Accordion allowMultiple defaultIndex={[]} w="100%" mb="40px">
              { 
                assetTypes.map((assetType, j)=>
                  assets?.filter(asset => asset.type === assetType.type && asset.biomeType === biomeType.type).length > 0 &&
                    <AccordionItem key={j} border="0" mt="18px">
                      {
                        ({isExpanded})=>
                          <>
                            <AccordionButton border="1px solid #fff" borderRadius="8px">
                              <Text>{assetType.name}</Text>
                              <Icon as={RightIcon} ml="auto" sx={{transform: isExpanded ? "rotate(90deg)" : ""}} transition="0.2s"/>
                            </AccordionButton>
                            
                            <AccordionPanel mt="8px" p="0" border="1px solid #fff" borderRadius="8px">
                              <Box display="flex" flexWrap="wrap" p="12px">
                                {
                                  assets?.filter(asset => asset.type === assetType.type && asset.biomeType === biomeType.type).sort((a,b) => (a.type > b.type || a.yielderType > b.yielderType || a.blockerState < b.blockerState || a.colorType > b.colorType) ? 1 : -1).map((asset, k)=>
                                    <Box key={k} w="33.33%" h="360px" padding="12px">
                                      <Box border="1px solid #fff" w="100%" h="100%" borderRadius="15px" pos="relative">
                                        <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${asset.image}`} w="auto" h="216px" sx={{pos: "absolute", left: "50%", top: "30%", transform: "translate(-50%,-30%)"}}/>
                                        <Text fontSize="24px" fontWeight="400" pos="absolute" left="20px" bottom="10px" maxW="200px">{asset.name}</Text>
                                        {userStates.role !== "levelDesigner" && <InputButtonIcon icon="edit" size="md" sx={{pos: "absolute", right: "20px", bottom: "15px"}} onClick={()=>navigate(`/level-editor/${page}/edit-${page.slice(0,-1)}/${asset.id}`)}/>}
                                      </Box>
                                    </Box>
                                  )
                                }
                              </Box> 
                            </AccordionPanel>
                          </>
                      }
                    </AccordionItem>
                )
              }
            </Accordion>
            
          </Box>
        )
      }
    </Box>
  )
}

export default Assets