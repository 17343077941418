import { useSelector, useDispatch } from "react-redux"
import { changeObjective01, changeObjective02, changeObjective03, changeMove, changeTime } from "../../../../redux/levelSlice"
import InterfaceAllSlices from "../../../../typescript/interfaceAllSlices"

import Spawner from "../../../../classes/getFromRedux/LevelEditor/Spawner"

import {Box, Text, Image} from "@chakra-ui/react"
import {motion, AnimatePresence} from "framer-motion"

import InputSelectText from "../../../Inputs/InputSelectText"
import InputNumber from "../../../Inputs/InputNumber"

const Objectives = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()  

  const spawner = new Spawner()

  function onChangeObjective01(type:number,yielderType:number,count:number){
    dispatch(changeObjective01({
      type: type,
      yielderType: yielderType,
      biomeType: levelStates.biomeType,
      count: count ? count : 0,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function onChangeObjective02(type:number,yielderType:number,count:number){
    dispatch(changeObjective02({
      type: type,
      yielderType: yielderType,
      biomeType: levelStates.biomeType,
      count: count ? count : 0,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function onChangeObjective03(type:number,yielderType:number,count:number){
    dispatch(changeObjective03({
      type: type,
      yielderType: yielderType,
      biomeType: levelStates.biomeType,
      count: count ? count : 0,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function onChangeMoveObjective(val:number){
    dispatch(changeMove({
      moveObjective: Number(val),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function onChangeTimeObjective(val:number){
    dispatch(changeTime({
      timeObjective: Number(val),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  return (
    <Box pt="100px" m="auto" w="320px">
      <Box mb="20px" minHeight="25px">
        {
          levelStates.objectiveDataContainer.gameMode === 0 ? <Text fontSize="16px" fontWeight="700" userSelect="none" margin="0 0 10px 0">Move: {levelStates.objectiveDataContainer.moveObjective}</Text> : <Text fontSize="16px" fontWeight="700" userSelect="none" margin="0 0 10px 0">Time: {levelStates.objectiveDataContainer.timeObjective}</Text>
        }
      </Box>

      <Box display="flex" flexDir="row" alignItems="center" mb="70px">
        <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" m="0 5px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
          {
            Boolean(levelStates.objectiveDataContainer.objective01.type) &&
            <>
              <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(levelStates.objectiveDataContainer.objective01.type, levelStates.objectiveDataContainer.objective01.yielderType)?.image}`} w="52px" h="52px" data-type={levelStates.objectiveDataContainer.objective01.type} data-yieldertype={levelStates.objectiveDataContainer.objective01.yielderType} as={motion.img} key={spawner.getOne(levelStates.objectiveDataContainer.objective01.type, levelStates.objectiveDataContainer.objective01.yielderType)?.id} initial={{scale: 0}} animate={{scale: 1}}/>
              <Text fontSize="20px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective01.count}</Text>
            </>
          }
        </Box>

        <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" m="0 5px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
          {
            Boolean(levelStates.objectiveDataContainer.objective02.type) &&
            <>
              <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(levelStates.objectiveDataContainer.objective02.type, levelStates.objectiveDataContainer.objective02.yielderType)?.image}`} w="52px" h="52px" data-type={levelStates.objectiveDataContainer.objective02.type} data-yieldertype={levelStates.objectiveDataContainer.objective02.yielderType} as={motion.img} key={spawner.getOne(levelStates.objectiveDataContainer.objective02.type, levelStates.objectiveDataContainer.objective02.yielderType)?.id} initial={{scale: 0}} animate={{scale: 1}}/>
              <Text fontSize="20px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective02.count}</Text>
            </>
          }
        </Box>

        <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" m="0 5px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
          {
            Boolean(levelStates.objectiveDataContainer.objective03.type) &&
            <>
              <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(levelStates.objectiveDataContainer.objective03.type, levelStates.objectiveDataContainer.objective03.yielderType)?.image}`} w="52px" h="52px" data-type={levelStates.objectiveDataContainer.objective03.type} data-yieldertype={levelStates.objectiveDataContainer.objective03.yielderType} as={motion.img} key={spawner.getOne(levelStates.objectiveDataContainer.objective03.type, levelStates.objectiveDataContainer.objective03.yielderType)?.id} initial={{scale: 0}} animate={{scale: 1}}/>
              <Text fontSize="20px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective03.count}</Text>
            </>
          }
        </Box>
      </Box>

      <Box display="flex" flexDir="column">
        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectText name="" sxDiv={{m: "0 20px 0 0"}} label="Objective 1" biggerLabel placeholder="Select..." colorfulDropdownTexts dropdownTexts={[spawner.getColorStones(), spawner.getByBiome(levelStates.biomeType)]} value={levelStates.objectiveDataContainer.objective01.type ? spawner.getOne(levelStates.objectiveDataContainer.objective01.type, levelStates.objectiveDataContainer.objective01.yielderType).name : ""} return={(dataInput)=>onChangeObjective01(dataInput.value.type, dataInput.value.yieldertype, 1)}/>
          <AnimatePresence>
            {
              levelStates.objectiveDataContainer.objective01.type && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber name="" sxDiv={{m:"0"}} label="Count" value={levelStates.objectiveDataContainer.objective01.count} return={(dataInput) => dataInput.value > 0 ? onChangeObjective01(levelStates.objectiveDataContainer.objective01.type, levelStates.objectiveDataContainer.objective01.yielderType, dataInput.value) : onChangeObjective01(0, 0, 0)}/></Box>
            }
          </AnimatePresence>
        </Box>

        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectText name="" sxDiv={{m: "0 20px 0 0"}} label="Objective 2" biggerLabel placeholder="Select..." colorfulDropdownTexts dropdownTexts={[spawner.getColorStones(), spawner.getByBiome(levelStates.biomeType)]} value={levelStates.objectiveDataContainer.objective02.type ? spawner.getOne(levelStates.objectiveDataContainer.objective02.type, levelStates.objectiveDataContainer.objective02.yielderType).name : ""} return={(dataInput)=>onChangeObjective02(dataInput.value.type, dataInput.value.yieldertype, 1)}/>
          <AnimatePresence>
            {
              levelStates.objectiveDataContainer.objective02.type && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber name="" sxDiv={{m:"0"}} label="Count" value={levelStates.objectiveDataContainer.objective02.count} return={(dataInput) => dataInput.value > 0 ? onChangeObjective02(levelStates.objectiveDataContainer.objective02.type, levelStates.objectiveDataContainer.objective02.yielderType, dataInput.value) : onChangeObjective02(0, 0, 0)}/></Box>
            }
          </AnimatePresence>
        </Box>

        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectText name="" sxDiv={{m: "0 20px 0 0"}} label="Objective 3" biggerLabel placeholder="Select..." colorfulDropdownTexts dropdownTexts={[spawner.getColorStones(), spawner.getByBiome(levelStates.biomeType)]} value={levelStates.objectiveDataContainer.objective03.type ? spawner.getOne(levelStates.objectiveDataContainer.objective03.type, levelStates.objectiveDataContainer.objective03.yielderType).name : ""} return={(dataInput)=>onChangeObjective03(dataInput.value.type, dataInput.value.yieldertype, 1)}/>
          <AnimatePresence>
            {
              levelStates.objectiveDataContainer.objective03.type && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber name="" sxDiv={{m:"0"}} label="Count" value={levelStates.objectiveDataContainer.objective03.count} return={(dataInput) => dataInput.value > 0 ? onChangeObjective03(levelStates.objectiveDataContainer.objective03.type, levelStates.objectiveDataContainer.objective03.yielderType, dataInput.value) : onChangeObjective03(0, 0, 0)}/></Box>
            }
          </AnimatePresence>
        </Box>

        <Box>
          {
            levelStates.objectiveDataContainer.gameMode === 0 ? <InputNumber name="" label='Move' min={1} value={levelStates.objectiveDataContainer.moveObjective} return={(dataInput)=>onChangeMoveObjective(dataInput.value)}/> : <InputNumber name="" label='Time' min={1} value={levelStates.objectiveDataContainer.timeObjective} return={(dataInput)=>onChangeTimeObjective(dataInput.value)}/>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default Objectives