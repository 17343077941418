import React, { FC } from "react"
import { Box, SystemStyleObject, Text, Textarea } from "@chakra-ui/react"

interface Props {
  name: string
  label?: string
  biggerLabel?: boolean
  loading?: boolean
  placeholder?: string
  sx?: SystemStyleObject
  sxDiv?: SystemStyleObject
  value: string
  return?: (data:{name:string, value:string}) => void
}

const InputTextarea:FC<Props> = (props:Props) => {

  function onChangeInput(value:any){

    const data = {
      name: props.name,
      value: value
    }
    props.return!(data)
  }
  
  return (
    <Box sx={{m: "0 0 20px 0", ...props.sxDiv}}>
      
      {
        props.label && !props.biggerLabel ? 
          <Text fontSize="12px" fontWeight="600" marginBottom="7.5px">{props.label}</Text> 
          : props.label && props.biggerLabel && <Text fontSize="16px" fontWeight="600" marginBottom="7.5px" color="#607B96" userSelect="none">{props.label}</Text>
      } 
      
      <Textarea 
        name={props.name}
        sx={{h: "90px", minH: "60px", maxH: "150px", ...props.sx}}
        border="1px solid #fff!important"
        _focus={{border: "1px solid #fff", boxShadow: "none"}}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e)=>onChangeInput(e.target.value)}
      />
    </Box>
  )
}

export default InputTextarea