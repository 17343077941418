import {BrowserRouter, Routes, Route} from "react-router-dom"
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// AUTH
import ProtectedRoutes from "./auth/ProtectedRoutes"
import PublicRoutes from "./auth/PublicRoutes"
import AuthorizatedRoutes from "./auth/AuthorizedRoutes"

// LAYOUT
import Layout from "./Components/Layout/Layout"

// PROTECTED PAGES
import PageHome from "./Pages/Home"

import PageLevelEditorHome from "./Pages/LevelEditor/Home"
import PageLevelEditorLevels from "./Pages/LevelEditor/Levels"
import PageLevelEditorLevel from "./Pages/LevelEditor/Level"
import PageLevelEditorAssets from "./Pages/LevelEditor/Assets"
import PageLevelEditorAsset from "./Pages/LevelEditor/Asset"

import PageIapEditorHome from "./Pages/IapEditor/Home"
import PageIapEditorBundles from "./Pages/IapEditor/Bundles"
import PageIapEditorBundle from "./Pages/IapEditor/Bundle"
import PageIapEditorOffers from "./Pages/IapEditor/Offers"
import PageIapEditorOffer from "./Pages/IapEditor/Offer"
import PageIapEditorProductIds from "./Pages/IapEditor/ProductIds"
import PageIapEditorBackgrounds from "./Pages/IapEditor/Backgrounds"

import PageUsers from "./Pages/Users"
import PageUser from "./Pages/User"
import PageLogout from "./Pages/Logout"
import PagePassword from "./Pages/Password"

// PUBLIC PAGES
import PageLogin from "./Pages/Login"
import ModalBackground from "./Components/ModalBackground"

// REDUX
import { useSelector, useDispatch } from "react-redux"
import InterfaceAllSlices from "./typescript/interfaceAllSlices"
import { collapsePopupBackground } from "./redux/popupManagerSlice"

function App() {  

  const popupStates = useSelector((state:InterfaceAllSlices)=>state.popupManager)
  const dispatch = useDispatch()
  
  return(
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoutes/>}>
            
            <Route path="/" element={<Layout header page={<PageHome/>}/>}/>
            <Route path="/change-password" element={<Layout header page={<PagePassword/>}/>}/>

            <Route element={<AuthorizatedRoutes whoCanSee={["admin"]}/>}>
              <Route path="/users" element={<Layout header page={<PageUsers/>}/>}/>
              <Route path="/users/new-user" element={<Layout header page={<PageUser/>}/>}/>
              <Route path="/users/edit-user/:id" element={<Layout header page={<PageUser/>}/>}/>
            </Route>

            <Route element={<AuthorizatedRoutes whoCanSee={["admin", "gameDesigner", "leadDesigner"]}/>}>
              <Route path="/level-editor/blockers/new-blocker" element={<Layout header page={<PageLevelEditorAsset/>}/>}/>
              <Route path="/level-editor/blockers/edit-blocker/:assetId" element={<Layout header page={<PageLevelEditorAsset/>}/>}/>
              <Route path="/level-editor/spawners/new-spawner" element={<Layout header page={<PageLevelEditorAsset/>}/>}/>
              <Route path="/level-editor/spawners/edit-spawner/:assetId" element={<Layout header page={<PageLevelEditorAsset/>}/>}/>
            </Route>

            <Route element={<AuthorizatedRoutes whoCanSee={["admin", "gameDesigner", "leadDesigner", "levelDesigner"]}/>}>
              <Route path="/level-editor" element={<Layout header rightSidebar page={<PageLevelEditorHome/>}/>}/>
              <Route path="/level-editor/levels" element={<Layout header page={<PageLevelEditorLevels/>}/>}/>
              <Route path="/level-editor/levels/new-level" element={<Layout header leftSidebar rightSidebar page={<PageLevelEditorLevel/>}/>}/>
              <Route path="/level-editor/levels/edit-level/:id" element={<Layout header leftSidebar rightSidebar page={<PageLevelEditorLevel/>}/>}/>
              <Route path="/level-editor/blockers" element={<Layout header page={<PageLevelEditorAssets/>}/>}/>
              <Route path="/level-editor/spawners" element={<Layout header page={<PageLevelEditorAssets/>}/>}/>
            </Route>

            <Route element={<AuthorizatedRoutes whoCanSee={["admin", "gameDesigner"]}/>}>
              <Route path="/in-app-purchases-editor" element={<Layout header page={<PageIapEditorHome/>}/>}/>
              <Route path="/in-app-purchases-editor/bundles" element={<Layout header page={<PageIapEditorBundles/>}/>}/>
              <Route path="/in-app-purchases-editor/bundles/new-bundle" element={<Layout header page={<PageIapEditorBundle/>}/>}/>
              <Route path="/in-app-purchases-editor/bundles/edit-bundle/:bundleId" element={<Layout header page={<PageIapEditorBundle/>}/>}/>
              <Route path="/in-app-purchases-editor/offers" element={<Layout header page={<PageIapEditorOffers/>}/>}/>
              <Route path="/in-app-purchases-editor/offers/new-offer" element={<Layout header page={<PageIapEditorOffer/>}/>}/>
              <Route path="/in-app-purchases-editor/offers/edit-offer/:offerId" element={<Layout header page={<PageIapEditorOffer/>}/>}/>
              <Route path="/in-app-purchases-editor/product-ids" element={<Layout header page={<PageIapEditorProductIds/>}/>}/>
              <Route path="/in-app-purchases-editor/backgrounds" element={<Layout header page={<PageIapEditorBackgrounds/>}/>}/>
            </Route>

          </Route>

          <Route element={<PublicRoutes/>}>
            <Route path="/login" element={<PageLogin/>}/>
          </Route>

          <Route path="/logout" element={<PageLogout/>}/>
        </Routes>
      </BrowserRouter>

      {/* {
        popupStates.popupBackground && <ModalBackground onClick={()=>dispatch(collapsePopupBackground(false))}/>
      } */}
      <ToastContainer position="bottom-center" theme="dark" autoClose={3000} draggable={false} closeOnClick={false} newestOnTop pauseOnHover/>
    </>
  )
}

export default App
