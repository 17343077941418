import { FC, LegacyRef, useRef } from "react"
import { Box } from "@chakra-ui/react"

interface Props {
  loading?: boolean
  setImageFile: (e:any)=>void
}

const InputFile:FC<Props> = (props) => {

  const inputRef:LegacyRef<any> = useRef()
  
  return (
    <Box pos="relative" display="flex" cursor={props.loading ? "not-allowed"  : "pointer"} opacity={props.loading ? 0.4 : 1}  sx={{"&:hover .newInput": !props.loading ? {bg: "#fff", color: "#000"} : ""}} onClick={()=>inputRef.current.click()}>
      <Box className="newInput" h="35px" w="95px" mr="10px" border="1px solid #fff" borderRadius="8px" display="flex" justifyContent="center" alignItems="center" userSelect="none" fontSize="14px" fontWeight="600" transition="200ms">Browse</Box>
      <Box className="newInput" h="35px" w="calc(100% - 105px)" border="1px solid #fff" borderRadius="8px" display="flex" justifyContent="start" pl="15px" alignItems="center" userSelect="none" fontSize="14px" fontWeight="600" transition="200ms">{"No file chosen"}</Box>
      <input 
        disabled={props.loading}
        hidden
        type="file"
        accept="image/png"
        ref={inputRef}
        onChange={(e)=>props.setImageFile(e.target.files ? e.target.files[0] : null)}
      />
    </Box>
  )
}

export default InputFile