import { configureStore, combineReducers } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"

import userReducer from "./userSlice"
import levelReducer from "./levelSlice"
import editorReducer from "./editorSlice"
import popupManagerReducer from "./popupManagerSlice"
import spawnersReducer from "./spawnersSlice"
import blockersReducer from "./blockersSlice"

const persistConfig = {
  key: "root",
  storage
}

const combinedReducers = combineReducers({
  user: userReducer,
  level: levelReducer,
  editor: editorReducer,
  popupManager: popupManagerReducer,
  spawners: spawnersReducer,
  blockers: blockersReducer,
})

const persistedReducer = persistReducer(persistConfig, combinedReducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  })
})

const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export { persistor}
export default store