import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import uuid from "react-uuid"

import { useAppSelector, useAppDispatch } from "../../redux/hooks/useTypedSelectors"
import { newSpawner, updateSpawner, removeSpawner } from "../../redux/spawnersSlice"
import { newBlocker, updateBlocker, removeBlocker } from "../../redux/blockersSlice"
import InterfaceAllSlices from "../../typescript/interfaceAllSlices"
import InterfaceBlocker from "../../typescript/interfaceBlocker"
import InterfaceSpawner from "../../typescript/interfaceSpawner"

import Blocker from "../../classes/getFromRedux/LevelEditor/Blocker"
import Spawner from "../../classes/getFromRedux/LevelEditor/Spawner"
import BiomeTypes from "../../classes/getTypes/LevelEditor/biomeTypes"
import BlockerTypes from "../../classes/getTypes/LevelEditor/blockerTypes"
import SpawnerTypes from "../../classes/getTypes/LevelEditor/spawnerTypes"

import { Box, Flex, Image, Text } from "@chakra-ui/react"
import { toast } from "react-toastify"

import InputButtonIcon from "../../Components/Inputs/InputButtonIcon"
import InputFile from "../../Components/Inputs/InputFile"
import InputSelectText from "../../Components/Inputs/InputSelectText"
import InputSlider from "../../Components/Inputs/InputSlider"
import InputText from "../../Components/Inputs/InputText"

const Asset = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const page = location.pathname.split("/")[2] //blockers or spawners
  const newOrEdit = location.pathname.split("/")[3].split("-")[0] //new or edit
  
  const assetsRequestStatusStates = useAppSelector((state:InterfaceAllSlices) => page === "blockers" ? state.blockers.requestStatus : state.spawners.requestStatus)
  const dispatch = useAppDispatch()
  const {assetId} = useParams()
  
  const [selectedBiomeType, setSelectedBiomeType] = useState(newOrEdit === "new" && location.state ? location.state.selectedBiome : 0)
  const [type, setType] = useState<number>(0)
  const [yielderType, setYielderType] = useState<number>()
  const [colorType, setColorType] = useState<number>()
  const [name, setName] = useState<string>("")
  const [blockerState, setBlockerState] = useState<number>(page === "blockers" ? 3 : 0)
  const [imageFile, setImageFile] = useState<File>()
  const [imageShow, setImageShow] = useState<string>()

  const [inputsLoading] = useState(false)
  const [buttonLoading] = useState({
    button1: false,
    button2: false,
    button3: false,
  })

  const biomeTypes = new BiomeTypes()
  const blockerTypes = new BlockerTypes()
  const spawnerTypes = new SpawnerTypes()

  useEffect(()=>{
    if(newOrEdit === "edit"){
      getAsset()
    }
  },[])

  function getAsset(){

    const storeItem:InterfaceBlocker | InterfaceSpawner | any = page === "blockers" ? new Blocker().getById(assetId ? assetId : "0") : new Spawner().getById(assetId ? assetId : "0")

    console.log(storeItem)

    if(storeItem){ 
      setSelectedBiomeType(storeItem.biomeType)
      setType(storeItem.type)
      setYielderType(storeItem.yielderType)
      setColorType(storeItem.colorType)
      setName(storeItem.name)
      setBlockerState(storeItem.blockerState)
      setImageShow(`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${storeItem.image}`)
    } else{
      navigate(`/level-editor/${page}`)
    }
    
  }

  function getImageBase64(){
    if(imageFile){
      const reader = new FileReader()

      reader.onload = () => {
        
        const src = reader.result as string
        setImageShow(src)
      }

      reader.readAsDataURL(imageFile)
    }
  }

  useEffect(()=>{
    getImageBase64()
  },[imageFile])

  function newAsset(){

    const imageName = `${uuid()}.png`

    const data = {
      id: String(assetId),
      biomeType: selectedBiomeType,
      type: Number(type),
      ...(Number(yielderType) && {yielderType: Number(yielderType)}),
      ...(Number(colorType) && {colorType: Number(colorType)}),
      name: name,
      ...(Number(blockerState) && {blockerState: Number(blockerState)}),
      image: imageShow && imageName,
      imageShow: imageShow,
      imageFile: imageFile,
      imageName: imageName
    }

    if(page === "blockers"){
      dispatch(newBlocker(data))
    } else if(page === "spawners"){
      dispatch(newSpawner(data))
    }
    
  }

  function updateAsset(){

    const imageName = `${uuid()}.png`

    const data = {
      id: String(assetId),
      biomeType: selectedBiomeType,
      type: Number(type),
      ...(Number(yielderType) && {yielderType: Number(yielderType)}),
      ...(Number(colorType) && {colorType: Number(colorType)}),
      name: name,
      ...(Number(blockerState) && {blockerState: Number(blockerState)}),
      image: imageShow && imageName,
      imageShow: imageShow,
      imageFile: imageFile,
      imageName: imageName
    }

    if(page === "blockers"){
      dispatch(updateBlocker(data))
    } else if(page === "spawners"){
      dispatch(updateSpawner(data))
    }
    
  }

  function removeAsset(){

    const data = {
      id: String(assetId)
    }
    
    if(page === "blockers"){
      dispatch(removeBlocker(data))
    } else if(page === "spawners"){
      dispatch(removeSpawner(data))
    }
    
  }

  useEffect(()=>{

    // setButtonLoading({...buttonLoading, button1: assetsRequestStatusStates.saveButtonLoading, button2: assetsRequestStatusStates.removeButtonLoading})

    if(assetsRequestStatusStates.error === true && assetsRequestStatusStates.message) {
      toast.error(assetsRequestStatusStates.message)
    }

    if(assetsRequestStatusStates.error === false && assetsRequestStatusStates.message) {
      toast.success(assetsRequestStatusStates.message)
      navigate(`/level-editor/${page}`)
    }

  },[assetsRequestStatusStates])

  useEffect(()=>{
    if(newOrEdit === "edit" && (!assetId) ){
      navigate(`/level-editor/${page}`)
    }
  },[])
  
  return (
    <Box pt="130px" w="1055px" display="flex" mx="auto">
      <Box display="flex" flexDir="column" w="520px">

        <Box mb="10px" color="#607B96" display="flex">
          <Text mr="10px" fontWeight="600">Biome:</Text>
          <Text textTransform="capitalize">{biomeTypes.getOne(selectedBiomeType)?.name}</Text>
        </Box>
        
        <Box display="flex" flexDir="row">
          <InputSelectText
            name="" 
            label="Type" 
            biggerLabel
            loading={inputsLoading} 
            placeholder="Select..." 
            sxDiv={{w: page === "spawners" && type === 2048 || page === "blockers" && (type === 268435456 || type === 1073741824) ? "62.5%" : "100%", mr: "auto"}} 
            colorfulDropdownTexts={page === "spawners" && selectedBiomeType === 0} 
            value={type && page === "blockers" ? blockerTypes.getOne(type).name : type && page === "spawners" ? spawnerTypes.getOne(type).name : undefined} 
            dropdownTexts={page === "blockers" ? [selectedBiomeType === 0 ? blockerTypes.getByBiome(0) : blockerTypes.getByOutOfBiome(0)] : [selectedBiomeType === 0 ? spawnerTypes.getByBiome(0) : spawnerTypes.getByOutOfBiome(0)]} 
            return={(dataInput)=>{setType(dataInput.value.type), setName(page === "blockers" ? blockerTypes.getOne(dataInput.value.type).name : spawnerTypes.getOne(dataInput.value.type).name)}}
          />

          {page === "spawners" && type === 2048 && 
            <InputSelectText
              name="" 
              label="Yielder Type" 
              biggerLabel
              loading={inputsLoading} 
              placeholder="Select..." 
              sxDiv={{w: "35%"}} 
              value={yielderType ? spawnerTypes.getOneYielder(yielderType).name : undefined} 
              dropdownTexts={[spawnerTypes.getYieldersByBiome(selectedBiomeType)]} 
              return={(dataInput)=>{setYielderType(dataInput.value.type), setName(spawnerTypes.getOneYielder(dataInput.value.type).name)}}
            />
          }

          {page === "blockers" && type === 268435456 && 
            <InputSelectText
              name="" 
              label="Color Type" 
              biggerLabel
              loading={inputsLoading} 
              placeholder="Select..." 
              sxDiv={{w: "35%"}} 
              value={colorType ? blockerTypes.getOneColor(colorType).name : undefined} 
              dropdownTexts={[blockerTypes.getAllColors()]} 
              return={(dataInput)=>{setColorType(dataInput.value.type), setName(blockerTypes.getOneColor(dataInput.value.type).name)}}/>
          }

          {page === "blockers" && type === 1073741824 && 
            <InputSelectText
              name="" 
              label="Color Type" 
              biggerLabel
              loading={inputsLoading} 
              placeholder="Select..." 
              sxDiv={{w: "35%"}} 
              value={colorType ? blockerTypes.getOneRainbow(colorType).name : undefined} 
              dropdownTexts={[blockerTypes.getAllRainbows()]} 
              return={(dataInput)=>{setColorType(dataInput.value.type), setName(blockerTypes.getOneRainbow(dataInput.value.type).name)}}/>
          }
        </Box>

        <InputText name="" label="Name" biggerLabel type="text" value={name} loading={inputsLoading} sx={{w: "100%"}} return={(dataInput)=>setName(dataInput.value)}/>
        
        {
          page === "blockers" && <InputSlider name="" label="State" biggerLabel min={1} max={3} sxDiv={{w:"100%"}} value={blockerState} return={(dataInput)=>setBlockerState(dataInput.value)}/>
        }
        <InputFile loading={inputsLoading} setImageFile={setImageFile}/>
      </Box>

      <Box ml="auto">
        <Flex justifyContent="center" alignItems="center" w="300px" h="300px" mb="16px" border="1px solid #fff" borderRadius="15px">
          <Image src={imageShow} w="auto" h="216px" sx={{"WebkitUserDrag": "none"}}/>
        </Flex>
        
        <Flex justifyContent="end">
          <InputButtonIcon icon="back" size="md" loading={buttonLoading.button3} sx={{mr: "10px"}} onClick={()=>navigate(-1)}/>
          {
            newOrEdit === "edit" && <InputButtonIcon icon="remove" size="md" loading={buttonLoading.button2} sx={{mr: "10px"}} onClick={()=>removeAsset()}/>
          }
          <InputButtonIcon icon="save" size="md" loading={buttonLoading.button1} onClick={()=> newOrEdit === "new" ? newAsset() : updateAsset()}/>
        </Flex>
      </Box>
    </Box>
  )
}

export default Asset