import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import InterfaceLevelSlice, { InterfaceTileDataContainers } from "../typescript/interfaceLevelSlice"

const tileDataContainers:InterfaceTileDataContainers[] = []

for (let i = 8; i >= 0; i--) {
  for (let k = 0; k <= 7; k++) {
    tileDataContainers.push({
      isBottomEndTile: i === 0 ? true : false,
      row: k,
      column: i,
      squareData: {},
      itemData: {},
      blockerData: []
    })
  }
}

const initialState:InterfaceLevelSlice = {
  xSize: 8,
  ySize: 9,
  levelNumber: 1,
  biomeType: 1,
  eventCollectionType: "",
  yielderType: 0,
  objectiveDataContainer: {
    objective01: {
      type: 0,
      yielderType: 0,
      biomeType: 0,
      count: 0,
      humanoidBreakableBlockerType: 1,
      humanoidCoverBlockerType: 1
    },
    objective02: {
      type: 0,
      yielderType: 0,
      biomeType: 0,
      count: 0,
      humanoidBreakableBlockerType: 1,
      humanoidCoverBlockerType: 1
    },
    objective03: {
      type: 0,
      yielderType: 0,
      biomeType: 0,
      count: 0,
      humanoidBreakableBlockerType: 1,
      humanoidCoverBlockerType: 1
    },
    timeObjective: 0,
    moveObjective: 0,
    gameMode: 0
  },
  tileDataContainers: tileDataContainers,
  randomStoneList: {
    hasBlueRatio: true,
    hasYellowRatio: true,
    hasPinkRatio: true,
    hasPurpleRatio: true,
    hasGreenRatio: true,
  },
  humanoidObstacleDataContainer: {
    moveFrequency: 0,
    humanoidObstacleTypes: 0,
    createBlockerOnStart: false
  },
  maxAnimals: 5,
  hintType: "BestMove",
  tutorialDataContainer: {},
  experience: 0,
  gold: 0,
  description: `This level created on ${new Date().toDateString()} at ${new Date().toLocaleTimeString()}`,
  createdBy: "",
  almight: {
    almightCharge: false,
    almight: 0
  },
  chat: [],
  edit: false,
  play: false,
  savedSpawners: []
}

const levelSlice = createSlice({
  name: "level",
  initialState,
  reducers: {
    changeBiome: (state, action) => {
      state.biomeType = action.payload
    },
    changeGameMode: (state, action) => {
      state.objectiveDataContainer.gameMode = action.payload
    },
    changeLevelNumber: (state, action) => {
      
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change level number.")
      } else {
        state.levelNumber = action.payload.levelNumber
      }
      
    },
    changeDescription: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change description.")
      } else {
        state.description = action.payload.description
      }
      
    },
    addNewMessage: (state, action) => {
      state.chat.push(action.payload)
    },
    changeXSize: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change width.")
      } else {
        state.xSize = action.payload.xSize
      }
      
    },
    changeYSize: (state, action) => {
      
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change height.")
      } else {
        state.ySize = action.payload.ySize
      }
      
    },
    toggleCellAvailability: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change cell availablity.")
      } else {
        
        state.tileDataContainers.map((cell)=>{
          if(cell.row === action.payload.x && cell.column === action.payload.y){
  
            if(cell.blockerData[0]?.type === 262144){

              const localBlockerData = [...cell.blockerData]

              localBlockerData.splice(0,-1)

              cell.blockerData = []
            } else {
              cell.isBottomEndTile = false
              cell.squareData = {}
              cell.itemData = {}

              const localBlockerData = [...cell.blockerData]
              
              const newObject = {
                type: 262144,
                colorType: 0,
                blockerState: 0,
                biomeType: 0,
                voidBlockerType: 1,
                humanoidCoverBlockerType: 1,
                humanoidBreakableBlockerType: 1,
              }

              localBlockerData.push(newObject)
              
              cell.blockerData = localBlockerData
            }
  
          }
        })
        
      }
      
    },
    toggleCellAsEndOfTile: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change cell to end of tile or not.")
      } else {
        
        state.tileDataContainers.map((cell)=>{
          if(cell.row === action.payload.x && cell.column === action.payload.y){
  
            if(cell.isBottomEndTile && cell.blockerData[0]?.type !== 262144){
              cell.isBottomEndTile = false
            } else if(!cell.isBottomEndTile && cell.blockerData[0]?.type !== 262144) {
              cell.isBottomEndTile = true
            }
  
          }
        })
        
      }
      
    },
    changeObjective01: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change objective 1.")
      } else {
        state.objectiveDataContainer.objective01.type = action.payload.type
        state.objectiveDataContainer.objective01.yielderType = action.payload.yielderType
        state.objectiveDataContainer.objective01.biomeType = action.payload.biomeType
        state.objectiveDataContainer.objective01.count = action.payload.count
      }
      
    },
    changeObjective02: (state, action) => {
      
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change objective 2.")
      } else {
        state.objectiveDataContainer.objective02.type = action.payload.type
        state.objectiveDataContainer.objective02.yielderType = action.payload.yielderType
        state.objectiveDataContainer.objective02.biomeType = action.payload.biomeType
        state.objectiveDataContainer.objective02.count = action.payload.count
      }
      
    },
    changeObjective03: (state, action) => {
     
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change objective 3.")
      } else {
        state.objectiveDataContainer.objective03.type = action.payload.type
        state.objectiveDataContainer.objective03.yielderType = action.payload.yielderType
        state.objectiveDataContainer.objective03.biomeType = action.payload.biomeType
        state.objectiveDataContainer.objective03.count = action.payload.count
      }
      
    },
    changeMove: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change move.")
      } else {
        state.objectiveDataContainer.moveObjective = action.payload.moveObjective
      }

    },
    changeTime: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change time.")
      } else {
        state.objectiveDataContainer.timeObjective = action.payload.timeObjective
      }

    },
    changeRandomStoneList: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change random stone list.")
      } else {
        state.randomStoneList = action.payload
      }
      
    },
    changeRewards: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change rewards.")
      } else {
        state.gold = action.payload.gold
        state.experience = action.payload.experience
      }
      
    },
    changeCellData: (state, action) => {
      
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change cells.")
      } else {
        const localTiles = [...state.tileDataContainers]
        
        localTiles.map((cell)=>{
          if(cell.row === action.payload.x && cell.column === action.payload.y){
  
            if(action.payload.newData.target === 0){
  
              if(Object.keys(cell.squareData).length === 0){

                cell.squareData = {
                  gameElementType: action.payload.newData.gameElementType,
                  spawnerType: action.payload.newData.spawnerType,
                  blueStoneRatio: action.payload.newData.blueStoneRatio,
                  yellowStoneRatio: action.payload.newData.yellowStoneRatio,
                  redStoneRatio: action.payload.newData.redStoneRatio,
                  purpleStoneRatio: action.payload.newData.purpleStoneRatio,
                  greenStoneRatio: action.payload.newData.greenStoneRatio,
                  animalChance: action.payload.newData.animalChance,
                  animal1Type: action.payload.newData.animal1Type,
                  animal2Type: action.payload.newData.animal2Type,
                  animal3Type: action.payload.newData.animal3Type,
                  animal1Count: action.payload.newData.animal1Count,
                  animal2Count: action.payload.newData.animal2Count,
                  animal3Count: action.payload.newData.animal3Count,
                }
                
              } else if(Object.keys(cell.squareData).length !== 0){
                cell.squareData = {}
              }
              
            }
  
            if(action.payload.newData.target === 1){
  
              if(Object.keys(cell.itemData).length === 0){

                cell.itemData = {
                  type: action.payload.newData.type,
                  yielderType: action.payload.newData.yielderType
                }
                
              } else if(Object.keys(cell.itemData).length !== 0){
                cell.itemData = {}
              }
              
            }
  
            if(action.payload.newData.target === 2){
  
              if(cell.blockerData.length === 0){

                const localBlockerData = [...cell.blockerData]
                
                const newObject = {
                  type: action.payload.newData.type,
                  colorType: action.payload.newData.colorType,
                  blockerState: action.payload.newData.blockerState,
                  biomeType: action.payload.newData.biomeType,
                  voidBlockerType: 1,
                  humanoidCoverBlockerType: 1,
                  humanoidBreakableBlockerType: 1,
                }

                localBlockerData.push(newObject)

                cell.blockerData = localBlockerData
                
              } else if(cell.blockerData.length !== 0){

                const localBlockerData = [...cell.blockerData]

                localBlockerData.splice(0,1)
                
                cell.blockerData = localBlockerData

              }
              
            }

          }
        })
  
        state.tileDataContainers = localTiles
      }

    },
    addNewSpawner: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to add spawner.")
      } else {
        const newSpawner = {
          gameElementType: action.payload.gameElementType,
          spawnerType: action.payload.spawnerType,
          blueStoneRatio: action.payload.blueStoneRatio,
          yellowStoneRatio: action.payload.yellowStoneRatio,
          redStoneRatio: action.payload.redStoneRatio,
          purpleStoneRatio: action.payload.purpleStoneRatio,
          greenStoneRatio: action.payload.greenStoneRatio,
          animalChance: action.payload.animalChance,
          animal1Type: action.payload.animal1Type,
          animal2Type: action.payload.animal2Type,
          animal3Type: action.payload.animal3Type,
          animal1Count: action.payload.animal1Count,
          animal2Count: action.payload.animal2Count,
          animal3Count: action.payload.animal3Count,
        }
  
        state.savedSpawners.push(newSpawner)
      }
      
    },
    removeSavedSpawner: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to remove spawner.")
      } else {
        const localSavedSpawners = [...state.savedSpawners]

        localSavedSpawners.splice(action.payload.value, 1)

        state.savedSpawners = localSavedSpawners
      }
      
    },
    changeMaxAnimals: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change rewards.")
      } else {
        state.maxAnimals = action.payload.maxAnimals
      }

    },
    changeAlmight: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change rewards.")
      } else {
        state.almight.almightCharge = action.payload.almightCharge
        state.almight.almight = action.payload.almight
      }
      
    },
    importLevel: (state, action) => {      
      
      state.xSize = action.payload.xSize,
      state.ySize = action.payload.ySize,
      state.levelNumber = action.payload.number,
      state.biomeType = action.payload.biomeType,
      state.eventCollectionType = action.payload.eventCollectionType,
      state.yielderType = action.payload.yielderType,
      state.objectiveDataContainer = {
        objective01: {
          type: action.payload.objectiveDataContainer.objective01.type,
          yielderType: action.payload.objectiveDataContainer.objective01.yielderType,
          biomeType: action.payload.objectiveDataContainer.objective01.biomeType,
          count: action.payload.objectiveDataContainer.objective01.count,
          humanoidBreakableBlockerType: action.payload.objectiveDataContainer.objective01.humanoidBreakableBlockerType,
          humanoidCoverBlockerType: action.payload.objectiveDataContainer.objective01.humanoidCoverBlockerType
        },
        objective02: {
          type: action.payload.objectiveDataContainer.objective02.type,
          yielderType: action.payload.objectiveDataContainer.objective02.yielderType,
          biomeType: action.payload.objectiveDataContainer.objective02.biomeType,
          count: action.payload.objectiveDataContainer.objective02.count,
          humanoidBreakableBlockerType: action.payload.objectiveDataContainer.objective02.humanoidBreakableBlockerType,
          humanoidCoverBlockerType: action.payload.objectiveDataContainer.objective02.humanoidCoverBlockerType
        },
        objective03: {
          type: action.payload.objectiveDataContainer.objective03.type,
          yielderType: action.payload.objectiveDataContainer.objective03.yielderType,
          biomeType: action.payload.objectiveDataContainer.objective03.biomeType,
          count: action.payload.objectiveDataContainer.objective03.count,
          humanoidBreakableBlockerType: action.payload.objectiveDataContainer.objective03.humanoidBreakableBlockerType,
          humanoidCoverBlockerType: action.payload.objectiveDataContainer.objective03.humanoidCoverBlockerType
        },
        timeObjective: action.payload.objectiveDataContainer.timeObjective,
        moveObjective: action.payload.objectiveDataContainer.moveObjective,
        gameMode: action.payload.objectiveDataContainer.gameMode
      },
      state.tileDataContainers = [
        ...action.payload.tileDataContainers.map((cell:any)=>({
          isBottomEndTile: cell.isBottomEndTile,
          row: cell.row,
          column: cell.column,
          squareData: Object.keys(cell.squareData).length !== 0 ? {
            gameElementType: cell.squareData?.gameElementType,
            spawnerType: cell.squareData?.spawnerType,
            blueStoneRatio: cell.squareData?.blueStoneRatio,
            yellowStoneRatio: cell.squareData?.yellowStoneRatio,
            redStoneRatio: cell.squareData?.redStoneRatio,
            purpleStoneRatio: cell.squareData?.purpleStoneRatio,
            greenStoneRatio: cell.squareData?.greenStoneRatio,
            animalChance: cell.squareData?.animalChance,
            animal1Type: cell.squareData?.animal1Type,
            animal2Type: cell.squareData?.animal2Type,
            animal3Type: cell.squareData?.animal3Type,
            animal1Count: cell.squareData?.animal1Count,
            animal2Count: cell.squareData?.animal2Count,
            animal3Count: cell.squareData?.animal3Count,
          } : {},
          itemData: Object.keys(cell.itemData).length !== 0 ? {
            type: cell.itemData?.type,
            yielderType: cell.itemData?.yielderType,
          } : {},
          blockerData: 
          cell.blockerData.length !== 0 ?
            ([{
              type: cell.blockerData[0]?.type,
              colorType: cell.blockerData[0]?.colorType,
              blockerState: cell.blockerData[0]?.blockerState,
              biomeType: cell.blockerData[0]?.biomeType,
              voidBlockerType: cell.blockerData[0]?.voidBlockerType,
              humanoidCoverBlockerType: cell.blockerData[0]?.humanoidCoverBlockerType,
              humanoidBreakableBlockerType: cell.blockerData[0]?.humanoidBreakableBlockerType,
            }]) : []
        }))
      ],
      state.randomStoneList = {
        hasBlueRatio: action.payload.randomStoneList.hasBlueRatio,
        hasYellowRatio: action.payload.randomStoneList.hasYellowRatio,
        hasPinkRatio: action.payload.randomStoneList.hasPinkRatio,
        hasPurpleRatio: action.payload.randomStoneList.hasPurpleRatio,
        hasGreenRatio: action.payload.randomStoneList.hasGreenRatio
      },
      state.humanoidObstacleDataContainer = {
        moveFrequency: action.payload.humanoidObstacleDataContainer.moveFrequency, //new ui needed to add this info
        humanoidObstacleTypes: action.payload.humanoidObstacleDataContainer.humanoidObstacleTypes, //new ui needed to add this info or None
        createBlockerOnStart: action.payload.humanoidObstacleDataContainer.createBlockerOnStart //new ui needed to add this info or False
      },
      state.maxAnimals = action.payload.maxAnimals,
      state.hintType = action.payload.hintType,
      state.experience = action.payload.experience,
      state.gold = action.payload.gold,
      state.description = action.payload.description,
      state.createdBy = action.payload.createdBy,
      state.almight = {
        almightCharge: action.payload.almight.almightCharge,
        almight: action.payload.almight.almight
      },
      state.chat = action.payload.chat.map((message:any)=>({
        name: message.name,
        message: message.message
      })),
      state.savedSpawners = action.payload.savedSpawners.map((savedSpawner:any)=>({
        blueStoneRatio: savedSpawner.blueStoneRatio,
        yellowStoneRatio: savedSpawner.yellowStoneRatio,
        redStoneRatio: savedSpawner.redStoneRatio,
        purpleStoneRatio: savedSpawner.purpleStoneRatio,
        greenStoneRatio: savedSpawner.greenStoneRatio,
        animalChance: savedSpawner.animalChance,
        animal1Type: savedSpawner.animal1Type,
        animal2Type: savedSpawner.animal2Type,
        animal3Type: savedSpawner.animal3Type,
        animal1Count: savedSpawner.animal1Count,
        animal2Count: savedSpawner.animal2Count,
        animal3Count: savedSpawner.animal3Count
      }))
    },
    resetLevel: () => initialState
  }
})

export const {changeBiome, changeGameMode, changeLevelNumber, changeDescription, addNewMessage, changeXSize, changeYSize, toggleCellAvailability, toggleCellAsEndOfTile,
  changeObjective01, changeObjective02, changeObjective03, changeMove, changeTime, changeRandomStoneList, changeRewards, 
  changeCellData, addNewSpawner, removeSavedSpawner, changeMaxAnimals, changeAlmight, importLevel, resetLevel} = levelSlice.actions
export default levelSlice.reducer