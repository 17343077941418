import React, { FC } from "react"
import {Box, Text, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Icon, SystemStyleObject} from "@chakra-ui/react"
import {BsChevronUp as UpIcon, BsChevronDown as DownIcon} from "react-icons/bs"

interface Props {
  name: string
  sx?: SystemStyleObject
  sxDiv?: SystemStyleObject
  min?: number
  max?: number
  warning?: string
  label?: string
  biggerLabel?: boolean
  loading?: boolean
  value: number
  return?: (data:{name:string, value:number}) => void
}

const InputNumber:FC<Props> = (props) => {

  function onChangeInput(value:any){

    const data = {
      name: props.name,
      value: value
    }
    props.return!(data)
  }
  
  return (
    <Box display="flex" flexDir="column" margin="0 0 20px 0" w="110px" sx={props.sxDiv}>
      
      {
        props.label && !props.biggerLabel ? 
          <Text fontSize="12px" fontWeight="600" marginBottom="7.5px">{props.label}</Text> 
          : props.label && props.biggerLabel && <Text fontSize="16px" fontWeight="600" marginBottom="7.5px" color="#607B96" userSelect="none">{props.label}</Text>
      } 
      
      <NumberInput name={props.name} w="100%" sx={props.sx} borderColor="#fff!important" focusBorderColor="#fff" opacity={props.loading ? 0.4 : 1} cursor={props.loading ? "notAllowed" : ""} outline="none" min={props.min} max={props.max} value={props.loading ? "Loading..." : props.value} isDisabled={props.loading} onChange={(valStr,valNum)=>onChangeInput(valNum)}>
        <NumberInputField height="35px" outline="none" borderColor="#fff!important" _focus={{boxShadow: "none"}} textAlign="center"/>
        <NumberInputStepper>
          <NumberIncrementStepper borderColor="#fff!important" _active={{backgroundColor: "transparent"}} className="stepperButton">
            <Icon as={UpIcon} sx={{
              ".stepperButton:active &": {
                transform: "scale(.9)"
              }
            }}/>
          </NumberIncrementStepper>
          <NumberDecrementStepper borderColor="#fff!important" _active={{backgroundColor: "transparent"}} className="stepperButton">
            <Icon as={DownIcon} sx={{
              ".stepperButton:active &": {
                transform: "scale(.9)"
              }
            }}/>
          </NumberDecrementStepper>
        </NumberInputStepper>
      </NumberInput>

      {
        props.warning && 
        <Box pt="8px">
          <Text fontSize="12px" fontWeight="300" color="#707070">{props.warning}</Text>
        </Box>
      }
    </Box>
  )
}

export default InputNumber