import { FC } from "react"
import {Box, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Image, SystemStyleObject} from "@chakra-ui/react"

interface Props {
  name: string
  sx?: SystemStyleObject
  sxDiv?: SystemStyleObject
  label?: string,
  biggerLabel?: boolean
  img?: string,
  min?: number
  max?: number
  value: number,
  return?: (data:{name:string, value:number}) => void
}

const InputSlider:FC<Props> = (props:Props) => {  

  function onChangeInput(value:any){

    const data = {
      name: props.name,
      value: value
    }
    props.return!(data)
  }
  
  return (
    <Box sx={props.sxDiv} margin="0 0 20px 0" display="flex" flexDirection={props.img ? "row" : "column"} alignItems={props.img ? "center" : "initial"} width="200px">
      {
        props.img && <Box w="60px"><Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${props.img}`} h="40px" w="auto" userSelect="none"/></Box>
      }

      {
        props.label && !props.biggerLabel && <Text fontSize="12px" fontWeight="700" marginBottom="7.5px" color="#607B96" userSelect="none">{props.label}: {props.value}</Text>
      }

      {
        props.label && props.biggerLabel && <Text fontSize="16px" fontWeight="600" marginBottom="7.5px" color="#607B96" userSelect="none">{props.label}: {props.value}</Text>
      }

      <Slider sx={props.sx} width={props.img ? "calc(100% - (60px + 50px))" : "100%"} mr="auto" min={props.min} max={props.max} value={props.value} onChange={(val)=>onChangeInput(val)}>
        <SliderTrack height="10px" backgroundColor="transparent" border="1px solid #fff" borderRadius="8px">
          <SliderFilledTrack backgroundColor="#607B96"/>
        </SliderTrack>
        <SliderThumb backgroundColor="#607B96" width="16px" height="16px" border="1px solid #fff!important" boxShadow="none!important" />
      </Slider>

      {
        props.img && <Text textAlign="right" color="#607B96" fontWeight="400" fontSize="16px" userSelect="none">{props.value}</Text>
      }
    </Box>
  )
}

export default InputSlider