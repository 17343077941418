export default [
  {
    type: 1,
    name: "Blue Flower"
  },
  {
    type: 2,
    name: "Yellow Flower"
  },
  {
    type: 3,
    name: "Red Flower"
  },
  {
    type: 4,
    name: "Purple Flower"
  },
  {
    type: 5,
    name: "Green Flower"
  },
  {
    type: 6,
    name: "Rainbow Flower"
  },
]