import {useState} from "react"
import { useNavigate } from "react-router-dom"
import axios from "../axios"
import {toast} from "react-toastify"

import { useSelector } from "react-redux"
import InterfaceAllSlices from "../typescript/interfaceAllSlices"

import { Box, Flex } from "@chakra-ui/react"

import InputText from "../Components/Inputs/InputText"
import InputButtonIcon from "../Components/Inputs/InputButtonIcon"

const Password = () => {

  const navigate = useNavigate()
  const userStates = useSelector((state:InterfaceAllSlices) => state.user)

  const [inputs, setInputs] = useState({
    new: "",
    confirm: ""
  })
  const [buttonLoading, setButtonLoading] = useState(false)

  function changePassword(){

    setButtonLoading(true)

    if(inputs.new === inputs.confirm){ 
      axios.put("/users/update-password", {
        email: userStates.email,
        password: inputs.new
      }).then((res)=>{
        toast.success(res.statusText)
        setButtonLoading(false)
        navigate(-1)
      }).catch((err)=>{
        toast.error(err.response.data.message)
        setButtonLoading(false)
      })
    } else {
      toast.error("Passwords are not matching.")
      setButtonLoading(false)
    }
  }
  
  return (
    <Box w="520px" m="130px auto 0 auto">
      <InputText type="password" label="New Password" name="new" value={inputs.new} onKeyDown={(e)=>e.key === "Enter" && changePassword()} return={(dataInput)=>setInputs({...inputs, [dataInput.name]: dataInput.value})}/>
      <InputText type="password" label="Confirm New Password" name="confirm" value={inputs.confirm} onKeyDown={(e)=>e.key === "Enter" && changePassword()} return={(dataInput)=>setInputs({...inputs, [dataInput.name]: dataInput.value})}/>

      <Flex ml="auto" w="fit-content">
        <InputButtonIcon icon="back" size="md" margin="0 10px 0 0" onClick={()=>navigate(-1)}/>
        <InputButtonIcon icon="save" loading={buttonLoading} size="md" margin="0 0 0 0" onClick={()=>changePassword()}/>
      </Flex>
    </Box>
  )
}

export default Password