import React, { FC, useEffect, useState } from "react"
import axios from "../../../../axios"

import { Box, Text } from "@chakra-ui/react"
import { RotatingLines as LoaderSpinner } from "react-loader-spinner"
import { motion } from "framer-motion"

import LevelCard from "../../../NotificationCard"

const Notifications:FC = () => {

  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)

  function getActivities(){
    setLoading(true)
    axios.get("/levels/activities?limit=20").then((res)=>{
      setNotifications(res.data)
      setLoading(false)
    }).catch((err)=>{
      console.log(err)
      setLoading(false)
    })
  }

  useEffect(()=>{
    getActivities()
  },[])
  
  return (
    <>
      {
        notifications.length > 0 ?
          notifications.map((notification,i)=>
            <Box key={i} as={motion.div} 
              initial={{y: 10, opacity: 0}}
              whileInView={{y: 0, opacity: 1}}
              viewport={{once:true}}
              transition={`${i*0.1}s`}
            >
              <LevelCard levelData={notification} showNotificationText showLevel showButtonShow showDescription showMessage width="330px" margin='0 auto 20px auto'/>
            </Box>
          )
          : loading ?
            <Box m="auto" w="fit-content">
              <LoaderSpinner width="25" strokeColor="#fff"/>
            </Box>
            : 
            <Text textAlign="center">No Data</Text>

      }
    </>
  )
}

export default Notifications