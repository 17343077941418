import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import InterfaceSpawner from "../typescript/interfaceSpawner"
import InterfaceSpawners from "../typescript/interfaceSpawners"
import { getAssets, addAsset, removeAsset, updateAsset } from "./services/assetsService"

interface InterfaceAssetData {
  id: string
  biomeType: number
  type: number
  yielderType?: number
  name: string
  image: string | undefined
  imageShow: string | undefined
  imageFile: File | undefined
  imageName: string
}

interface InterfaceRemoveAssetData {
  id: string
}

export const getSpawners = createAsyncThunk("spawners/getSpawners", async () =>{
  try{
    return await getAssets("spawners")
  }catch(err:any){
    return err.response.data.message
  }
})

export const newSpawner = createAsyncThunk("spawners/newSpawner", async (assetData:InterfaceAssetData) =>{
  try{
    return await addAsset("spawners", assetData)
  }catch(err:any){
    return "Adding new spawner process successfully failed."
  }
})

export const updateSpawner = createAsyncThunk("spawners/updateSpawner", async (assetData:InterfaceAssetData) =>{
  try{
    return await updateAsset("spawners", assetData)
  }catch(err:any){
    return "Adding new spawner process successfully failed."
  }
})

export const removeSpawner = createAsyncThunk("spawners/removeSpawner", async (assetData:InterfaceRemoveAssetData) =>{
  try{
    return await removeAsset("spawners", assetData)
  }catch(err:any){
    return "Removing spawner process successfully failed.."
  }
})

const initialState:InterfaceSpawners = {
  spawners: [

  ],
  requestStatus: {
    loading: false,
    saveButtonLoading: false,
    removeButtonLoading: false,
    error: false,
    message: "",
    redirect: ""
  }
}

const spawnersSlice = createSlice({
  name: "spawners",
  initialState,
  reducers: {
    resetSpawnerRequestStatus: (state) => {
      state.requestStatus = initialState.requestStatus
    }
  },
  extraReducers: (builder) => {
    //get all spawners
    builder.addCase(getSpawners.pending, (state)=>{
      state.requestStatus.loading = true
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = ""
    })
    builder.addCase(getSpawners.fulfilled, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = ""

      state.spawners = action.payload.data.sort((a:InterfaceSpawner,b:InterfaceSpawner)=>(a.type > b.type || a.yielderType && b.yielderType && a.yielderType > b.yielderType) ? 1 : -1)
    })
    builder.addCase(getSpawners.rejected, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = true
      state.requestStatus.message = action.payload
    })

    //new spawner
    builder.addCase(newSpawner.pending, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = true
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = ""
    })
    builder.addCase(newSpawner.fulfilled, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = "Spawner created successfully."

      const currentAssets = [...state.spawners]
      
      currentAssets.push({
        id: action.payload.data.item.id,
        biomeType: action.payload.data.item.biomeType,
        type: action.payload.data.item.type,
        yielderType: action.payload.data.item.yielderType,
        name: action.payload.data.item.name,
        image: action.payload.data.item.image,
      })

      state.spawners = currentAssets.sort((a:InterfaceSpawner,b:InterfaceSpawner)=>(a.type > b.type || a.yielderType && b.yielderType && a.yielderType > b.yielderType) ? 1 : -1)
    })
    builder.addCase(newSpawner.rejected, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = true
      state.requestStatus.message = "Spawner couldn't create for some reason."
    })

    //update spawner
    builder.addCase(updateSpawner.pending, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = true
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = ""
    })
    builder.addCase(updateSpawner.fulfilled, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = "Spawner updated successfully."

      const currentAssets = [...state.spawners]
      
      currentAssets.map((asset)=>{
        if(asset.id === action.payload.data.item.id){
          asset.biomeType = action.payload.data.item.biomeType,
          asset.type = action.payload.data.item.type,
          asset.yielderType = action.payload.data.item.yielderType,
          asset.name = action.payload.data.item.name,
          asset.image = action.payload.data.item.image
        }
      })

      state.spawners = currentAssets.sort((a:InterfaceSpawner,b:InterfaceSpawner)=>(a.type > b.type || a.yielderType && b.yielderType && a.yielderType > b.yielderType) ? 1 : -1)
    })
    builder.addCase(updateSpawner.rejected, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = true
      state.requestStatus.message = "Spawner couldn't update for some reason."
    })

    //remove spawner
    builder.addCase(removeSpawner.pending, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = true
      state.requestStatus.error = false
      state.requestStatus.message = ""
    })
    builder.addCase(removeSpawner.fulfilled, (state, action:PayloadAction<any>)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = false
      state.requestStatus.message = "Spawner removed successfully."

      const currentAssets = [...state.spawners]

      currentAssets.map((asset, i)=>{
        if(asset.id === action.payload.data.item.id){
          currentAssets.splice(i,1)
        }
      })

      state.spawners = currentAssets.sort((a:InterfaceSpawner,b:InterfaceSpawner)=>(a.type > b.type || a.yielderType && b.yielderType && a.yielderType > b.yielderType) ? 1 : -1)
    })
    builder.addCase(removeSpawner.rejected, (state)=>{
      state.requestStatus.loading = false
      state.requestStatus.saveButtonLoading = false
      state.requestStatus.removeButtonLoading = false
      state.requestStatus.error = true
      state.requestStatus.message = "Spawner couldn't remove for some reason."
    })
  },
})

export const { resetSpawnerRequestStatus } = spawnersSlice.actions
export default spawnersSlice.reducer