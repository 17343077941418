import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import axios from "axios"

import InterfaceBundle from "../../typescript/interfaceBundle"

import ItemTypes from "../../classes/getTypes/IapEditor/itemTypes"

import { Box, Flex, Text } from "@chakra-ui/react"
import { toast } from "react-toastify"

import InputButtonIcon from "../../Components/Inputs/InputButtonIcon"
import InputSelectText from "../../Components/Inputs/InputSelectText"
import InputText from "../../Components/Inputs/InputText"
import InputNumber from "../../Components/Inputs/InputNumber"
import { motion } from "framer-motion"

const Bundle = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const page = location.pathname.split("/")[2] //blockers or spawners
  const newOrEdit = location.pathname.split("/")[3].split("-")[0] //new or edit
  const {bundleId} = useParams()
  const [inputsLoading, setInputsLoading] = useState(false)
  const [buttonLoading, setButtonsLoading] = useState({
    actionButtons: false,
    backButton: false,
  })
  
  const itemTypes = new ItemTypes()
  
  const [datas, setDatas] = useState([
    {
      id: 100,
      name: "Starter Bundle",
      bundleData: {
        items: [
          {
            gameItem: {
              id: 1
            },
            gameCount: 1000
          }
        ]
      }
    },
    {
      id: 101,
      name: "Galaxy Bundle",
      bundleData: {
        items: [
          {
            gameItem: {
              id: 2
            },
            gameCount: 1000
          },
          {
            gameItem: {
              id: 1
            },
            gameCount: 1
          }
        ]
      }
    },
    {
      id: 102,
      name: "Stellar Bundle",
      bundleData: {
        items: [
          {
            gameItem: {
              id: 3
            },
            gameCount: 1000
          },
          {
            gameItem: {
              id: 1
            },
            gameCount: 1
          },
          {
            gameItem: {
              id: 2
            },
            gameCount: 20
          },
        ]
      }
    },
  ])
  
  const [data, setData] = useState<InterfaceBundle>({
    id: 0,
    name: "",
    bundleData: {
      items: []
    }
  })

  function getData(){
    
    let localData:any
    setInputsLoading(true)
    setButtonsLoading({
      actionButtons: true,
      backButton: false
    })
    
    setTimeout(()=>{
      
      axios.get(`http://localhost:4000/bundles/${bundleId}`).then((res:any)=>{
        setData(res.data)
        setInputsLoading(false)
        setButtonsLoading({
          actionButtons: false,
          backButton: false
        })
      }).catch((err:any)=>{
        console.log(err)
        setInputsLoading(false)
        setButtonsLoading({
          actionButtons: false,
          backButton: false
        })
        navigate(-1)
      })

    }, 1000)
    
  }

  function changeName(value:string){
    setData(prev=>({...prev, name: value}))
  }

  function addItem(){

    const newItem = {
      gameItem: {
        id: 1
      },
      gameCount: 1000
    }
    
    setData(prev=>({...prev, bundleData: {...prev.bundleData, items: [...prev.bundleData.items, newItem]}}))
  }

  function changeItemId(itemIndex:number, itemNewData:number){

    const localItems = [...data.bundleData.items]

    localItems.map((item,i)=>{
      if(i === itemIndex){
        item.gameItem.id = itemNewData
      }
    })
    
    setData(prev=>({...prev, bundleData: {...prev.bundleData, items: [...localItems]}}))
  }

  function changeItemCount(itemIndex:number, itemNewData:number){

    const localItems = [...data.bundleData.items]

    localItems.map((item,i)=>{
      if(i === itemIndex){
        item.gameCount = itemNewData
      }
    })
    
    setData(prev=>({...prev, bundleData: {...prev.bundleData, items: [...localItems]}}))
  }

  function removeItem(itemIndex:number){

    const localItems = [...data.bundleData.items]

    localItems.map((item,i)=>{
      if(i === itemIndex){
        localItems.splice(i,1)
      }
    })
    
    setData(prev=>({...prev, bundleData: {...prev.bundleData, items: [...localItems]}}))
  }

  useEffect(()=>{
    if(newOrEdit === "edit"){
      getData()
    }
  },[])
  
  return (
    <Box pt="130px" w="1055px" display="flex" mx="auto">
      <Box display="flex" flexDir="column" w="520px">

        <Box mb="10px" color="#607B96" display="flex">
          <Text mr="10px" fontWeight="600">ID:</Text>
          <Text textTransform="capitalize">{data.id}</Text>
        </Box>

        <InputText name="" label="Name" biggerLabel type="text" value={data.name} loading={inputsLoading} sx={{w: "100%"}} return={(dataInput)=>changeName(dataInput.value)}/>

        <Box display="flex" flexDir="column" mb="10px">
          <Text mb="10px" color="#607B96" fontWeight="600">Items:</Text> 

          {
            data.bundleData.items.length > 0 &&
              data.bundleData.items.map((item,i)=>
                <Box key={i} display="flex" flexDir="row" mb="10px"
                  as={motion.div} initial={{top: "-20px", opacity: 0}} animate={{top: "0px", opacity: 1}} transition={`${i*0.1}s`}
                >
                  <InputSelectText 
                    name=""
                    label="Name"
                    biggerLabel 
                    loading={inputsLoading} 
                    placeholder="Select..." 
                    value={itemTypes.getOne(item.gameItem.id).name}
                    dropdownTexts={[itemTypes.getAll()]} 
                    sxDiv={{mr: "2.5%", w: "62.5%", mb: 0}}
                    return={(dataInput)=>changeItemId(i, Number(dataInput.value.id))}
                  />
                  <InputNumber
                    name=""
                    label="Count"
                    biggerLabel
                    value={item.gameCount}
                    min={1}
                    return={(dataInput)=>changeItemCount(i, dataInput.value)}
                    sxDiv={{mr: "2.5%", w: "22.5%", mb: 0}}
                  />
                  <InputButtonIcon
                    icon="remove"
                    onClick={(e)=>removeItem(i)}
                    size="md"
                    sx={{w: "100%", height: "35px!important", aspectRatio: "auto", borderRadius: "8px"}}
                    sxDiv={{w: "10%", display: "flex", alignItems: "end"}}
                  />
                </Box>
              )
          }

          <InputButtonIcon
            icon="add"
            size="md"
            loading={buttonLoading.actionButtons}
            sx={{w: "100%", height: "35px!important", aspectRatio: "auto", borderRadius: "8px"}}
            sxDiv={{display: "flex", alignItems: "end"}}
            onClick={(e)=>addItem()}
          />
        </Box>

        <Flex justifyContent="end">
          <InputButtonIcon icon="back" size="md" loading={buttonLoading.backButton} sx={{mr: "10px"}} onClick={()=>navigate("/in-app-purchases-editor/bundles")}/>
          {
            newOrEdit === "edit" && <InputButtonIcon icon="remove" size="md" loading={buttonLoading.actionButtons} sx={{mr: "10px"}} onClick={()=>null}/>
          }
          <InputButtonIcon icon="save" size="md" loading={buttonLoading.actionButtons} onClick={()=> newOrEdit === "new" ? null : null}/>
        </Flex>

      </Box>

    </Box>
  )
}

export default Bundle