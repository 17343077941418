import {useEffect, useState} from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import axios from "../axios"
import {toast} from "react-toastify"

import { Box, Flex } from "@chakra-ui/react"

import InputText from "../Components/Inputs/InputText"
import InputSelectText from "../Components/Inputs/InputSelectText"
import InputButtonIcon from "../Components/Inputs/InputButtonIcon"

const roles = [
  [
    {
      name: "admin",
    },
    {
      name: "leadDesigner",
    },
    {
      name: "levelDesigner",
    }
  ]
]

const User = () => {

  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()

  const [purpose] = useState(location.pathname.split("/")[2])
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    role: ""
  })
  const [inputsLoading, setInputsLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState({
    button1: false,
    button2: false,
    button3: false
  })

  useEffect(()=>{

    if(purpose === "edit-user"){

      setInputsLoading(true)
      setButtonLoading({button1: true, button2: true, button3: true})
      
      axios.get(`users/${id}`).then((res)=>{
        setInputs({...inputs, name: res.data.name, email: res.data.email, role: res.data.role})
        setInputsLoading(false)
        setButtonLoading({button1: false, button2: false, button3: false})
      }).catch((err)=>{
        toast.error(err.response.data.message)
        setInputsLoading(false)
        setButtonLoading({button1: false, button2: false, button3: false})
      })
    }
    
  },[purpose])

  function newUser(){

    setButtonLoading((prev)=>({...prev, button1: true}))
    
    axios.post("/users", {
      name: inputs.name, email: inputs.email, role: inputs.role
    }).then((res)=>{
      toast.success(res.statusText)
      setButtonLoading((prev)=>({...prev, button1: false}))
      navigate("/users")
    }).catch((err)=>{
      toast.error(err.response.data.message)
      setButtonLoading((prev)=>({...prev, button1: false}))
    })
  }

  function updateUser(){

    setButtonLoading((prev)=>({...prev, button1: true}))
    
    axios.put("/users", {
      id: id, 
      name: inputs.name, 
      email: inputs.email,
      role: inputs.role
    }).then((res)=>{
      toast.success(res.statusText)
      setButtonLoading((prev)=>({...prev, button1: false}))
      navigate("/users")
    }).catch((err)=>{
      toast.error(err.response.data.message)
      setButtonLoading((prev)=>({...prev, button1: false}))
    })
  }

  function deleteUser(){

    setButtonLoading((prev)=>({...prev, button2: false}))
    
    axios.delete(`/users/${id}`).then((res)=>{
      toast.success(res.statusText)
      setButtonLoading((prev)=>({...prev, button2: false}))
      navigate("/users")
    }).catch((err)=>{
      toast.error(err.response.data.message)
      setButtonLoading((prev)=>({...prev, button2: false}))
    })
  }
  
  return (
    <Box w="520px" m="130px auto 0 auto">
      <InputText type="text" label="Name" name="name" value={inputs.name} loading={inputsLoading} return={(dataInput)=>setInputs(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>
      <InputText type="email" label="E-Mail" name="email" value={inputs.email} loading={inputsLoading} return={(dataInput)=>setInputs(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>
      <InputSelectText label="Role" name="role" sxDiv={{w:"100%"}} placeholder="Select..." loading={inputsLoading} dropdownTexts={roles} value={inputs.role} return={(dataInput)=>setInputs(prev=>({...prev, [dataInput.name]: dataInput.value.name}))}/>
      <Flex ml="auto" w="fit-content">
        <InputButtonIcon icon="back" loading={buttonLoading.button3} size="md" margin="0 10px 0 0" onClick={()=>navigate(-1)}/>
        {
          purpose === "edit-user" && <InputButtonIcon icon="remove" loading={buttonLoading.button2} size="md" margin="0 10px 0 0" onClick={()=>deleteUser()}/>
        }
        <InputButtonIcon icon="save" loading={buttonLoading.button1} size="md" margin="0 0 0 0" onClick={()=> purpose === "new-user" ? newUser() : purpose === "edit-user" ? updateUser() : null}/>
      </Flex>
    </Box>
  )
}

export default User