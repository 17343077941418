import { createSlice } from "@reduxjs/toolkit"
import InterfaceEditorSlice from "../typescript/interfaceEditorSlice"

const initialState:InterfaceEditorSlice = {
  sidebar: "General Level Settings",
  clipboard:{
    target: 0,
    type: 0,
    yielderType: 0,
    colorType: 0,
    blockerState: 0,
    biomeType: 0,
    gameElementType: 0,
    spawnerType: 0,
    blueStoneRatio: 0,
    yellowStoneRatio: 0,
    pinkStoneRatio: 0,
    purpleStoneRatio: 0,
    greenStoneRatio: 0,
    animalChance: 0,
    animal1Type: 0,
    animal2Type: 0,
    animal3Type: 0,
    animal1Count: 0,
    animal2Count: 0,
    animal3Count: 0,
  },
  cellsLevel: 0,
  editable: false,
  purpose: "new"
}

const editorSlice = createSlice({
  name: "editor",
  initialState: initialState,
  reducers: {
    changeSidebar: (state, action) => {
      state.sidebar = action.payload
    },
    changeClipboard: (state, action) => {
      state.clipboard = action.payload
    },
    changeCellsLevel: (state, action) => {
      state.cellsLevel = action.payload
    },
    changeEditable: (state, action) => {
      state.editable = action.payload
    },
    changePurpose: (state, action) => {
      state.purpose = action.payload
    }
  }
})

export const {changeSidebar, changeClipboard, changeCellsLevel, changeEditable, changePurpose} = editorSlice.actions
export default editorSlice.reducer