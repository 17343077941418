export default [
  {
    type: 524288,
    name: "Breakable Blocker",
    biome: undefined
  },
  {
    type: 1048576,
    name: "Biome Specific Cover Blocker",
    biome: undefined
  },
  {
    type: 33554432,
    name: "Humanoid Cover Blocker",
    biome: 0
  },
  {
    type: 67108864,
    name: "Humanoid Breakable Blocker",
    biome: 0
  },
  {
    type: 134217728,
    name: "Biome Essence (Collectible)",
    biome: undefined
  },
  {
    type: 268435456,
    name: "Color Blocker",
    biome: undefined
  },
  {
    type: 536870912,
    name: "Reinforced Blocker",
    biome: undefined
  },
  {
    type: 1073741824,
    name: "Rainbow Collectible Blocker",
    biome: undefined
  },
  {
    type: 2147483648,
    name: "Regen Blocker",
    biome: undefined
  },
]