export default [
  {
    type: 1,
    name: "Random Stone",
    biome: 0
  },
  {
    type: 2,
    name: "Blue Stone",
    biome: 0
  },
  {
    type: 4,
    name: "Yellow Stone",
    biome: 0
  },
  {
    type: 8,
    name: "Red Stone",
    biome: 0
  },
  {
    type: 16,
    name: "Purple Stone",
    biome: 0
  },
  {
    type: 32,
    name: "Green Stone",
    biome: 0
  },
  {
    type: 64,
    name: "Beam V",
    biome: 0
  },
  {
    type: 128,
    name: "Beam H",
    biome: 0
  },
  {
    type: 256,
    name: "Plasma",
    biome: 0
  },
  {
    type: 512,
    name: "Snitch",
    biome: 0
  },
  {
    type: 1024,
    name: "Vortex",
    biome: 0
  },
  {
    type: 2048,
    name: "Droppable",
    biome: undefined
  }
]