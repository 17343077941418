import React, { FC } from "react"
import { Box, Button } from "@chakra-ui/react"
import {MdOutlineFileDownload as DownloadIcon} from "react-icons/md"
import {BiRefresh as RefreshIcon} from "react-icons/bi"
import {BiSend as SendIcon} from "react-icons/bi"

interface Props {
  label: string,
  icon: "save" | "refresh" | "send"
  onClick: (e:any) => void
}

const InputButtonText:FC<Props> = (props) => {
  return (
    <Box>
      <Button leftIcon={
        props.icon === "save" ? <DownloadIcon fontSize="24px"/> 
          : props.icon === "refresh" ? <RefreshIcon style={{transform: "rotateY(180deg)"}} fontSize="24px"/>
            : props.icon === "send" ? <SendIcon fontSize="24px"/>
              : <></>
      } iconSpacing="2px" backgroundColor="transparent" padding="0" fontSize="12px" fontWeight="700" height="25px" _hover={{backgroundColor: "transparent"}} _active={{backgroundColor: "transparent", transform: "scale(.95)"}} onClick={props.onClick}>
        {props.label}
      </Button>
    </Box>
  )
}

export default InputButtonText