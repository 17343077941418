import { Box, Text } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import InterfaceAllSlices from "../typescript/interfaceAllSlices"

const Home = () => {

  const userStates = useSelector((state:InterfaceAllSlices)=>state.user)

  const menuItems = [
    {
      name: "Level Editor",
      url: "level-editor",
      whoCanSee: ["admin", "gameDesigner", "leadDesigner", "levelDesigner"]
    },
    {
      name: "In App Purchases Editor",
      url: "in-app-purchases-editor",
      whoCanSee: ["admin", "gameDesigner"]
    },
    {
      name: "Wheel of Fortune Editor",
      url: "wheel-of-fortune-editor",
      whoCanSee: ["admin", "gameDesigner"]
    },
    {
      name: "Events Editor",
      url: "events-editor",
      whoCanSee: ["admin", "gameDesigner"]
    }
  ]
  
  return (
    <Box pos="relative" h="100vh">
      <Box pos="absolute" top="50%" left="50%" w="100%" maxW="1920px" maxH="100%" sx={{transform: "translate(-50%, -50%)"}} display="flex" flexWrap="wrap" p="20px">

        {
          menuItems.filter((menuItem:any)=>menuItem.whoCanSee.includes(userStates.role)).map((menuItem, i)=>  
            <Box key={i} w="25%" minW="calc(1880px / 7)" sx={{aspectRatio: "1"}} p="20px">
              <Box userSelect="none" border="1px solid #1E2D3D" textAlign="center" w="100%" h="100%" cursor="pointer" _hover={{borderColor: "#2e4c6b", "& p": {color: "#fff"}}}>
                <Link to={menuItem.url} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
                  <Text fontSize="30px" fontWeight="700" color="#999">{menuItem.name}</Text>
                </Link>
              </Box>
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

export default Home