import { useEffect, useState } from "react"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import axios from "../../axios"
import { toast } from "react-toastify"

import { useSelector, useDispatch } from "react-redux"
import { changeObjective01, changeObjective02, changeObjective03, changeCellData, changeMove, changeTime, changeRewards, importLevel, resetLevel } from "../../redux/levelSlice"
import { changeCellsLevel, changeEditable } from "../../redux/editorSlice"
import InterfaceAllSlices from "../../typescript/interfaceAllSlices"

import Spawner from "../../classes/getFromRedux/LevelEditor/Spawner"
import Blocker from "../../classes/getFromRedux/LevelEditor/Blocker"

import {Box, Button, Icon, Text, Image, Img} from "@chakra-ui/react"
import {IoCloseSharp as CloseIcon} from "react-icons/io5"
import {motion, AnimatePresence} from "framer-motion"
import {RotatingLines as LoaderSpinner} from "react-loader-spinner"

import InputButtonText from "../../Components/Inputs/InputButtonText"
import InputSwitch from "../../Components/Inputs/InputSwitch"
import InputSliderVertical from "../../Components/Inputs/InputSliderVertical"
import spawnerStoneImg from "../../assets/images/spawners/spawner.png"


const Level = () => {

  const spawner = new Spawner()
  const blocker = new Blocker()

  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [purpose] = useState(location.pathname.split("/")[2].split("-")[0]) //new - edit
  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  const [levelLoading, setLevelLoading] = useState(false)
  const [stoneRatios, setStoneRatios] = useState({
    blueStoneRatio: 0,
    yellowStoneRatio: 0,
    redStoneRatio: 0,
    purpleStoneRatio: 0,
    greenStoneRatio: 0,
  })
  const [animals, setAnimals] = useState({
    animal1: {yielderType:0,count:0},
    animal3: {yielderType:0,count:0},
    animal2: {yielderType:0,count:0},
  })
  const [animalChance, setAnimalChance] = useState(50)

  function removeObjectives(){
    dispatch(changeObjective01({
      type: 0, 
      yielderType: 0, 
      count: 0, 
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
    dispatch(changeObjective02({
      type: 0, 
      yielderType: 0, 
      count: 0, 
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
    dispatch(changeObjective03({
      type: 0, 
      yielderType: 0, 
      count: 0, 
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function removeMoveAndTime(){
    dispatch(changeMove({
      moveObjective: 0,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
    dispatch(changeTime({
      timeObjective: 0,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function removeMateriaAndExperience(){
    dispatch(changeRewards({
      gold:0, 
      experience:0,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function hoverCell(x?: number, y?: number){

    if((typeof x === "number" && x === 0 || x) && (typeof y === "number" && y === 0 || y)){
      levelStates.tileDataContainers.map((cell)=>{
        if(cell.row === x && cell.column === y){
          setStoneRatios({
            blueStoneRatio: cell.squareData.blueStoneRatio ? cell.squareData.blueStoneRatio : 0,
            yellowStoneRatio: cell.squareData.yellowStoneRatio ? cell.squareData.yellowStoneRatio : 0,
            redStoneRatio: cell.squareData.redStoneRatio ? cell.squareData.redStoneRatio : 0,
            purpleStoneRatio: cell.squareData.purpleStoneRatio ? cell.squareData.purpleStoneRatio : 0,
            greenStoneRatio: cell.squareData.greenStoneRatio ? cell.squareData.greenStoneRatio : 0,
          })
          setAnimals({
            animal1: {yielderType: cell.squareData.animal1Type ? cell.squareData.animal1Type : 0, count: cell.squareData.animal1Count ? cell.squareData.animal1Count : 0},
            animal2: {yielderType: cell.squareData.animal2Type ? cell.squareData.animal2Type : 0, count: cell.squareData.animal2Count ? cell.squareData.animal2Count : 0},
            animal3: {yielderType: cell.squareData.animal3Type ? cell.squareData.animal3Type : 0, count: cell.squareData.animal3Count ? cell.squareData.animal3Count : 0},
          })
          setAnimalChance(cell.squareData.animalChance ? cell.squareData.animalChance : 0)
        }
      })
    } else {
      setStoneRatios({
        blueStoneRatio: 0,
        yellowStoneRatio: 0,
        redStoneRatio: 0,
        purpleStoneRatio: 0,
        greenStoneRatio: 0,
      })
      setAnimals({
        animal1: {yielderType: 0, count: 0},
        animal2: {yielderType: 0, count: 0},
        animal3: {yielderType: 0, count: 0},
      })
      setAnimalChance(0)
    }
  }

  function changeCellDataFunc(x:number, y:number){

    if(editorStates.clipboard.target === 0 && editorStates.cellsLevel !== 0){
      dispatch(changeCellsLevel(0))
    } else if(editorStates.clipboard.target === 1 && editorStates.cellsLevel !== 1){
      dispatch(changeCellsLevel(1))
    } else if(editorStates.clipboard.target === 2 && editorStates.cellsLevel !== 2){
      dispatch(changeCellsLevel(2))
    }

    levelStates.tileDataContainers.map((cell)=>{

      if(cell.row === x && cell.column === y){    

        if(cell.blockerData[0]?.type !== 262144){ 

          const data = {
            x: x, 
            y: y, 
            newData: editorStates.clipboard, 
            cellsLevel: editorStates.cellsLevel,
            editable: editorStates.purpose === "new" ? true : editorStates.editable
          }
          
          dispatch(changeCellData(data))
        }
      }
      
    })
    
  }

  function getLevel(){

    setLevelLoading(true)
    
    axios.get(`/levels?id=${id}`).then((res)=>{      
      dispatch(importLevel(res.data))
      setLevelLoading(false)
    }).catch((err)=>{
      console.log(err)
      setLevelLoading(false)
    })
  }

  useEffect(()=>{
    if(id){
      getLevel()
    }
  },[id])

  function newLevel(){

    axios.post("/levels", {...levelStates, number: levelStates.levelNumber}).then((res)=>{
      toast.success(res.statusText)
      navigate("/level-editor/levels")
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })

  }

  function updateLevel(){

    setLevelLoading(true)
    
    axios.put("/levels", {...levelStates, number: levelStates.levelNumber, id: id}).then((res)=>{
      toast.success(res.data)
      navigate("/level-editor/levels")
      setLevelLoading(false)
    }).catch((err)=>{
      toast.error(err.response.data.message)
      setLevelLoading(false)
    })
    
  }

  return (
    <Box pos="relative" h="100%">

      {
        levelLoading && 
          <Box pos="fixed" w="100vw" h="100vh" left="0" top="0" zIndex="100" bg="#111111cc">
            <Box pos="fixed" top="50%" left="50%" sx={{transform: "translate(-50%,-50%)"}}>
              <LoaderSpinner strokeColor="#aaa" width="30px"/>
            </Box>
          </Box>
      }
      
      <Box pos="absolute" left="0" top="0" p="16px">
        <InputButtonText label="Save Level" icon="save" onClick={()=>purpose === "new" ? newLevel() : purpose === "edit" ? updateLevel() : null}/>
        <InputButtonText label="Refresh" icon="refresh" onClick={()=>dispatch(resetLevel())}/>
      </Box>

      {
        purpose === "edit" &&
        <Box pos="absolute" right="0" top="0" p="16px">
          <InputSwitch name="" activeLabel="Edit" deactiveLabel="View" value={editorStates.editable} return={() => dispatch(changeEditable(!editorStates.editable))}/>
        </Box>
      }

      <Box pos="absolute" left="0" bottom="0">
        <InputSliderVertical name="" borderLeft={false} value={editorStates.cellsLevel} return={(dataInput) => dispatch(changeCellsLevel(dataInput.value))}/>
      </Box>

      <Box pos="absolute" left="50%" top="52px" w="400px" display="flex" flexDir="column" sx={{transform: "translateX(-50%)"}}>
        
        <Box w="100%" h="150px" display="flex" justifyContent="space-between">
          <Box w="49%" h="100%" border="2px solid #fff" borderRadius="8px" pos="relative" px="24px" display="flex" alignItems="center">

            <Button position="absolute" top="0" right="0" p="0" w="15px" h="15px" minW="initial" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>removeObjectives()}>
              <Icon as={CloseIcon} fontSize="10px"/>
            </Button>

            <Box display="flex" flexDir="column" w="100%">

              <Text fontSize="10px" fontWeight="700" mb="2.5px">Objectives</Text>

              <Box display="flex" flexDir="row" justifyContent="space-between" alignItems="center" w="100%">
                <Box w="46px" h="46px" border="1px solid #fff" borderRadius="8px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
                  {
                    levelStates.objectiveDataContainer.objective01.type ?
                      <>
                        <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(levelStates.objectiveDataContainer.objective01.type, levelStates.objectiveDataContainer.objective01.yielderType)?.image}`} w="26px" h="26px" as={motion.img} key={spawner.getOne(levelStates.objectiveDataContainer.objective01.type, levelStates.objectiveDataContainer.objective01.yielderType)?.id} initial={{scale: 0}} animate={{scale: 1}}/>
                        <Text fontSize="10px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective01.count}</Text>
                      </>
                      : ""
                  }
                </Box>

                <Box w="46px" h="46px" border="1px solid #fff" borderRadius="8px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
                  {
                    levelStates.objectiveDataContainer.objective02.type ?
                      <>
                        <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(levelStates.objectiveDataContainer.objective02.type, levelStates.objectiveDataContainer.objective02.yielderType)?.image}`} w="26px" h="26px" as={motion.img} key={spawner.getOne(levelStates.objectiveDataContainer.objective02.type, levelStates.objectiveDataContainer.objective02.yielderType)?.id} initial={{scale: 0}} animate={{scale: 1}}/>
                        <Text fontSize="10px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective02.count}</Text>
                      </>
                      : ""
                  }
                </Box>

                <Box w="46px" h="46px" border="1px solid #fff" borderRadius="8px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
                  {
                    levelStates.objectiveDataContainer.objective03.type ?
                      <>
                        <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(levelStates.objectiveDataContainer.objective03.type, levelStates.objectiveDataContainer.objective03.yielderType)?.image}`} w="26px" h="26px" as={motion.img} key={spawner.getOne(levelStates.objectiveDataContainer.objective03.type, levelStates.objectiveDataContainer.objective03.yielderType)?.id} initial={{scale: 0}} animate={{scale: 1}}/>
                        <Text fontSize="10px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective03.count}</Text>
                      </>
                      : ""
                  }
                </Box>
              </Box>

            </Box>
          </Box>

          <Box w="49%" h="100%" display="flex" flexDir="column" justifyContent="space-between">
            <Box w="100%" h="30%" border="2px solid #fff" borderRadius="8px" pos="relative" display="flex" alignItems="center">
              <Button position="absolute" top="0" right="0" p="0" w="15px" h="15px" minW="initial" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>removeMoveAndTime()}>
                <Icon as={CloseIcon} fontSize="10px"/>
              </Button>

              <Box fontSize="10px" px="16px">
                <Box display="flex">
                  <Text fontWeight="700" mr="2.5px">Move:</Text>
                  <Text fontWeight="400">{levelStates.objectiveDataContainer.moveObjective === 0 ? "-" : levelStates.objectiveDataContainer.moveObjective}</Text>
                </Box>
                <Box display="flex">
                  <Text fontWeight="700" mr="2.5px">Time:</Text>
                  <Text fontWeight="400">{levelStates.objectiveDataContainer.timeObjective === 0 ? "-" : levelStates.objectiveDataContainer.timeObjective}</Text>
                </Box>
              </Box>
            </Box>

            <Box w="100%" h="30%" border="2px solid #fff" borderRadius="8px" pos="relative" display="flex" alignItems="center">
              <Button position="absolute" top="0" right="0" p="0" w="15px" h="15px" minW="initial" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>removeMateriaAndExperience()}>
                <Icon as={CloseIcon} fontSize="10px"/>
              </Button>

              <Box fontSize="10px" px="16px">
                <Box display="flex">
                  <Text fontWeight="700" mr="2.5px">Materia:</Text>
                  <Text fontWeight="400">{levelStates.gold}</Text>
                </Box>
                <Box display="flex">
                  <Text fontWeight="700" mr="2.5px">Experience:</Text>
                  <Text fontWeight="400">{levelStates.experience}</Text>
                </Box>
              </Box>
            </Box>

            <Box w="100%" h="30%" border="2px solid #fff" borderRadius="8px" pos="relative" display="flex" alignItems="center">
              <Button position="absolute" top="0" right="0" p="0" w="15px" h="15px" minW="initial" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>console.log("remove")}>
                <Icon as={CloseIcon} fontSize="10px"/>
              </Button>

              <Box fontSize="10px" px="16px">
                <Box>
                  <Text fontWeight="700">Active Humanoid Blockers:</Text>
                  <Text fontWeight="400">Cover, Breakable</Text>
                </Box>
              </Box>
            </Box>

          </Box>
        </Box>

        <Box mt="52px">
          <Box display="flex" flexDir="column" w="fit-content" m="auto" flexWrap="wrap" onMouseLeave={()=>hoverCell()}>
            {
              [...Array(levelStates.ySize)].map((row,i)=>
                <Box key={i} display="flex">
                  {
                    levelStates.tileDataContainers.filter((cell)=>cell.column === i && cell.row < levelStates.xSize).map((cell,k)=>                    
                      <Box key={k} w="46px" h="46px" m="2px" pos="relative" overflow="hidden" border={cell.blockerData[0]?.type !== 262144 ? "1px solid #fff" : "1px solid #333"} filter={cell.blockerData[0]?.type === 262144 ? "grayscale(80%)" : ""} borderBottomStyle={cell.isBottomEndTile ? "dotted" : "solid"} borderRadius="8px" borderBottomLeftRadius={cell.isBottomEndTile ? "2px" : "8px"} borderBottomRightRadius={cell.isBottomEndTile ? "2px" : "8px"} transition="200ms" 
                        onMouseOver={()=>hoverCell(cell.row, cell.column)} 
                        onClick={()=>changeCellDataFunc(cell.row, cell.column)}
                      >
                        {
                          Boolean(Object.keys(cell.squareData).length !== 0) &&
                          <Box pos="absolute" zIndex={1} top="50%" left="50%" display="flex" justifyContent="center" sx={{transform: "translate(-50%,-50%)"}} w="30px" h="30px">
                            <Img src={spawnerStoneImg} w="100%" h="100%" userSelect="none" as={motion.img} initial={{scale:0}} animate={{scale:1}} exit={{scale:0}}/>
                          </Box>
                        }

                        <AnimatePresence>
                          {
                            Boolean(editorStates.cellsLevel >= 1 && Object.keys(cell.itemData).length !== 0) &&
                            <Box pos="absolute" zIndex={2} top="50%" left="50%" display="flex" justifyContent="center" sx={{transform: "translate(-50%,-50%)"}} w="30px" h="30px">
                              <Img src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne( cell.itemData.type, cell.itemData.yielderType)?.image}`} w="100%" h="100%" userSelect="none" data-type={cell.itemData.type} data-yieldertype={cell.itemData.yielderType} as={motion.img} initial={{scale:0}} animate={{scale:1}} exit={{scale:0}}/>
                            </Box>
                          }
                        </AnimatePresence>

                        <AnimatePresence>
                          {
                            Boolean(editorStates.cellsLevel === 2 && cell.blockerData.length !== 0 && cell.blockerData[0]?.type !== 262144) &&
                            <Box pos="absolute" zIndex={3} top="50%" left="50%" display="flex" justifyContent="center" sx={{transform: "translate(-50%,-50%)"}} w="35px" h="35px">
                              <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${blocker.getOne( cell.blockerData[0]?.type, cell.blockerData[0]?.colorType, cell.blockerData[0]?.blockerState, cell.blockerData[0]?.biomeType)?.image}`} w="100%" h="100%" userSelect="none" data-type={cell.blockerData[0]?.type} data-colortype={cell.blockerData[0]?.colorType} data-blockerstate={cell.blockerData[0]?.blockerState} data-biometype={cell.blockerData[0]?.biomeType} as={motion.img} initial={{scale:0}} animate={{scale:1}} exit={{scale:0}}/>
                            </Box>
                          }
                        </AnimatePresence>
                      </Box>
                    )
                  }
                </Box>
              ).sort((a,b) => a > b ? 1 : -1)
            }
          </Box>
        </Box>

        <AnimatePresence>
          {
            (stoneRatios.blueStoneRatio > 0 || stoneRatios.yellowStoneRatio > 0 || stoneRatios.redStoneRatio > 0 || stoneRatios.purpleStoneRatio > 0 || stoneRatios.greenStoneRatio > 0 || animals.animal1.count > 0 || animals.animal2.count > 0 || animals.animal3.count > 0) &&
            <Box mt="20px" w="400px" as={motion.div}>
              <Text fontSize="14px">Spawner</Text>
              <Box display="flex" flexDir="row" mt="10px">

                <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                  <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(2).image}`} w="30px" h="30px"/>
                  <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                    <Text fontSize="11px" fontWeight="600">%{stoneRatios.blueStoneRatio ? stoneRatios.blueStoneRatio : "0"}</Text>
                  </Box>
                </Box>

                <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                  <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(4).image}`} w="30px" h="30px"/>
                  <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                    <Text fontSize="11px" fontWeight="600">%{stoneRatios.yellowStoneRatio ? stoneRatios.yellowStoneRatio : "0"}</Text>
                  </Box>
                </Box>

                <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                  <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(8).image}`} w="30px" h="30px"/>
                  <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                    <Text fontSize="11px" fontWeight="600">%{stoneRatios.redStoneRatio ? stoneRatios.redStoneRatio : "0"}</Text>
                  </Box>
                </Box>

                <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                  <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(16).image}`} w="30px" h="30px"/>
                  <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                    <Text fontSize="11px" fontWeight="600">%{stoneRatios.purpleStoneRatio ? stoneRatios.purpleStoneRatio : "0"}</Text>
                  </Box>
                </Box>

                <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                  <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(32).image}`} w="30px" h="30px"/>
                  <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                    <Text fontSize="11px" fontWeight="600">%{stoneRatios.greenStoneRatio ? stoneRatios.greenStoneRatio : "0"}</Text>
                  </Box>
                </Box>

                <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                  <Box display="flex" justifyContent="center" alignItems="center" m="auto">
                    <Text fontSize="18px" fontWeight="700">AC</Text>
                  </Box>
                  <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                    <Text fontSize="11px" fontWeight="600">%{animalChance ? animalChance : "0"}</Text>
                  </Box>
                </Box>

                {
                  animals.animal1.count > 0 &&
                  <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(2048,animals.animal1.yielderType).image}`} w="30px" h="30px"/>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">{animals.animal1.count}</Text>
                    </Box>
                  </Box>
                }

                {
                  animals.animal2.count > 0 &&
                  <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(2048,animals.animal2.yielderType).image}`} w="30px" h="30px"/>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">{animals.animal2.count}</Text>
                    </Box>
                  </Box>
                }

                {
                  animals.animal3.count > 0 &&
                  <Box display="flex" flexDir="column" alignItems="center" mr="10px" as={motion.div} initial={{scale: 0}} animate={{scale: 1}}>
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(2048,animals.animal3.yielderType).image}`} w="30px" h="30px"/>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">{animals.animal3.count}</Text>
                    </Box>
                  </Box>
                }

              </Box>
            </Box>
          }
        </AnimatePresence>
        
      </Box>
    </Box>
  )
}

export default Level