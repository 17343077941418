export default [
  {
    id: 1,
    name: "Materia",
  },
  {
    id: 2,
    name: "Soul Crystal",
  },
  {
    id: 3,
    name: "Power Stones",
  },
  {
    id: 4,
    name: "Boosters",
  },
]