import { createSlice } from "@reduxjs/toolkit"
import InterfacePopupManager from "../typescript/interfacePopupManager"

const initialState:InterfacePopupManager = {
  popupNewLevel: {
    status: false
  },
  popupError: {
    status: false,
    title: "Error",
    description: "An unexpected error has occurred."
  },
  popupDelete: {
    status: false,
    title: "ARE YOU SURE THAT YOU WANT TO DELETE IT?",
    description: "You won't be able to revert."
  },
  popupBackground: false
}

const popupManagerSlice = createSlice({
  name: "popupManager",
  initialState,
  reducers: {
    collapsePopupNewLevel: (state, action) => {
      state.popupNewLevel.status = action.payload
    },
    collapsePopupError: (state, action) => {
      state.popupError.status = action.payload
    },
    collapsePopupDelete: (state, action) => {
      state.popupDelete.status = action.payload
    },
    collapsePopupBackground: (state, action) => {
      state.popupBackground = action.payload
    },
  }
})

export const {collapsePopupNewLevel, collapsePopupError, collapsePopupDelete, collapsePopupBackground} = popupManagerSlice.actions
export default popupManagerSlice.reducer