import {FC, useState} from "react"
import { useNavigate } from "react-router-dom"

import { useDispatch } from "react-redux"
import { collapsePopupNewLevel } from "../../redux/popupManagerSlice"
import { changeBiome, changeGameMode, resetLevel } from "../../redux/levelSlice"
import { changePurpose } from "../../redux/editorSlice"

import {Box, Button, Icon, Heading, Image, Text} from "@chakra-ui/react"
import {IoCloseSharp as CloseIcon} from "react-icons/io5"

import {motion} from "framer-motion"

import InputSwitch from "../Inputs/InputSwitch"
import InputButton from "../Inputs/InputButton"

import Biomes from "../../classes/getTypes/LevelEditor/biomeTypes"

const PopupNewLevel:FC = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const biomes = new Biomes()
  const [selectedBiome, setSelectedBiome] = useState(1)
  const [gameMode, setGameMode] = useState(false)

  function createNewLevel(){
    dispatch(collapsePopupNewLevel(false))
    dispatch(resetLevel())
    dispatch(changeBiome(selectedBiome))
    dispatch(changeGameMode(gameMode === true ? 1 : 0))
    dispatch(changePurpose("new"))
    navigate("/level-editor/levels/new-level")
  }
  
  return (
    <Box>
      <Box as={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} backgroundColor="#000000aa" position="fixed" top="0" left="0" width="100vw" height="100vh" zIndex="11" onClick={()=>dispatch(collapsePopupNewLevel(false))}/>
      <Box as={motion.div} initial={{transform: "translate(-50%,-50%) scale(0.1)"}} animate={{transform: "translate(-50%,-50%) scale(1)"}} position="fixed" top="50%" left="50%" transform="translate(-50%,-50%)" zIndex="12" w="372.5px" h="535px" border="2px solid #fff" borderRadius="8px" p="32px 25px" bg={"#000"}>
        
        <Button position="absolute" top="0" right="0" padding="10px" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>dispatch(collapsePopupNewLevel(false))}>
          <Icon as={CloseIcon}/>
        </Button>

        <Heading as="h4" textTransform="uppercase" fontSize="20px" fontWeight="600" textAlign="center" mb="32px" userSelect="none">Select a Biome</Heading>

        <Box display="flex" flexWrap="wrap">
          {
            biomes.getAll().slice(1).map((biomeData,i)=>
              <BiomeBox key={i} biomeData={biomeData} current={biomeData.type === selectedBiome} onClick={()=> biomeData.status === true && setSelectedBiome(biomeData.type)}/>
            )
          }
        </Box>

        <Box display="flex" flexDir="row" justifyContent="center" m="32px 0 25px 0">
          <Text fontSize="16px" fontWeight="600" mr="10px">Rush Mode</Text>
          <InputSwitch name="" activeLabel='On' deactiveLabel='Off' value={gameMode} return={(dataInput)=>setGameMode(dataInput.value)}/>
        </Box>

        <InputButton label='Done' margin="0 auto 0 auto" width="110px" onClick={()=>createNewLevel()}/>

      </Box>
    </Box>
  )
}

interface BiomeBoxProps {
  biomeData: {
    type: number,
    name: string,
    img: string,
    status: boolean
  }
  current: boolean
  onClick?: () => void
}

const BiomeBox:FC<BiomeBoxProps> = (props) => {
  return (
    <Box w="33.33%" style={{aspectRatio: "1/1.2"}} p="7.5px">
      <Box cursor={props.biomeData.status ? "pointer" : "default"} onClick={props.onClick}>
        <Box border={props.biomeData.status ? "2px solid #fff" : "2px solid #7A7A7A"} borderRadius="8px" boxShadow={(props.biomeData.status && props.current) ? "0 0 10px 1px #fff" : ""} display="flex" justifyContent="center" alignItems="center" style={{aspectRatio: 1}} transition="0.2s">
          <Image src={props.biomeData.img} maxW="65%" filter={props.biomeData.status ? "" : "invert(55%)"} userSelect="none"/>
        </Box>
        <Text color={props.biomeData.status ? "#fff" : "#7A7A7A"} textShadow={(props.biomeData.status && props.current) ? "0 0 10px#fff" : ""} textAlign="center" fontSize="16px" fontWeight="600" mt="2.5px" userSelect="none" transition="0.2s">{props.biomeData.name}</Text>
      </Box>
    </Box>
  )
}

export default PopupNewLevel