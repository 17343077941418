import { FC, useEffect } from "react"
import { Outlet, Navigate, useLocation } from "react-router-dom"

import { useSelector } from "react-redux"
import InterfaceAllSlices from "../typescript/interfaceAllSlices"

interface Props {
  whoCanSee: string[]
}

const AuthorizatedRoutes:FC<Props> = (props) => {

  const location = useLocation()
  const userStates = useSelector((state:InterfaceAllSlices) => state.user)
  
  function verify(){
    
    if(props.whoCanSee.includes(userStates.role)){
      return true
    } else {
      return false
    }
  }

  useEffect(()=>{
    verify()
  },[location.pathname])

  return (
    verify() ? <Outlet/> : <Navigate to="/"/>
  )
}

export default AuthorizatedRoutes