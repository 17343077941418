import axios from "axios"

function getToken(){
  const allStates = localStorage.getItem("persist:root")

  if(allStates){ 
    const parsedAllStates = JSON.parse(allStates)
    const parsedUser = JSON.parse(parsedAllStates.user)
    const token = parsedUser.token
    return token
  } else{
    return null
  }
}

export default axios.create({
  baseURL: `https://editor-api.nora.lostctrlbackendservices.com/${process.env.REACT_APP_API_TYPE}`,
  headers: {
    Authorization: `Bearer ${getToken()}`
  }
})