import {FC} from "react"

import { Box, IconButton, Icon, SystemStyleObject } from "@chakra-ui/react"

import {IoAddSharp as AddIcon, IoPencilOutline as EditIcon, IoEyeOutline as ShowIcon, IoTrashOutline as RemoveIcon} from "react-icons/io5"
import {MdRefresh as RefreshIcon} from "react-icons/md"
import {AiOutlineSave as SaveIcon} from "react-icons/ai"
import {TiArrowBack as BackIcon} from "react-icons/ti"
import {FiChevronDown as DownIcon, FiChevronUp as UpIcon} from "react-icons/fi"

import {RotatingLines as LoaderSpinner} from "react-loader-spinner"

interface Props {
  margin?: string
  size?: "md"
  icon: "add" | "edit" | "remove" | "show" | "save" | "back" | "refresh" | "down" | "up"
  sx?: SystemStyleObject
  sxDiv?: SystemStyleObject
  loading?: boolean
  onClick: (e:any) => void
}

const InputButtonIcon:FC<Props> = (props) => {

  const buttonProps = {
    add: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={AddIcon} size={props.size}/>,
      background: "#4DC313",
      _hover: {"background": !props.loading ? "#4DC313cc" : ""},
      _active: {"background": !props.loading ? "#4DC313aa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
    edit: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={EditIcon} size={props.size}/>,
      background: "#FFC228",
      _hover: {"background": !props.loading ? "#FFC228cc" : ""},
      _active: {"background": !props.loading ? "#FFC228aa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
    remove: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={RemoveIcon} size={props.size}/>,
      background: "#EC0D0D",
      _hover: {"background": !props.loading ? "#EC0D0Dcc" : ""},
      _active: {"background": !props.loading ? "#EC0D0Daa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
    show: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={ShowIcon} size={props.size}/>,
      background: "#995EE4",
      _hover: {"background": !props.loading ? "#995EE4cc" : ""},
      _active: {"background": !props.loading ? "#995EE4aa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
    save: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={SaveIcon} size={props.size}/>,
      background: "#316CF3",
      _hover: {"background": !props.loading ? "#316CF3cc" : ""},
      _active: {"background": !props.loading ? "#316CF3aa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
    back: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={BackIcon} size={props.size}/>,
      background: "#CECECE",
      _hover: {"background": !props.loading ? "#CECECEcc" : ""},
      _active: {"background": !props.loading ? "#CECECEaa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
    refresh: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={RefreshIcon} size={props.size}/>,
      background: "#EFEFEF",
      _hover: {"background": !props.loading ? "#EFEFEFcc" : ""},
      _active: {"background": !props.loading ? "#EFEFEFaa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
    down: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={DownIcon} size={props.size}/>,
      background: "#AAA",
      _hover: {"background": !props.loading ? "#EFEFEFcc" : ""},
      _active: {"background": !props.loading ? "#EFEFEFaa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
    up: {
      icon: props.loading ? <LoaderSpinner width="20px" strokeColor="#000"/> : <ButtonIcon as={UpIcon} size={props.size}/>,
      background: "#AAA",
      _hover: {"background": !props.loading ? "#EFEFEFcc" : ""},
      _active: {"background": !props.loading ? "#EFEFEFaa" : "", "transform": !props.loading ? "scale(0.95)" : ""}
    },
  }
  
  return (
    <Box m={props.margin ? props.margin : "0"} sx={props.sxDiv}>
      <IconButton 
        aria-label=""
        {...buttonProps[props.icon]}
        borderRadius="16px" size="xs" width={props.size === "md" ? "48px" : "24px"} height="auto" sx={{aspectRatio: "3/2", ...props.sx}} disabled={props.loading} onClick={props.onClick}
      />
    </Box>
  )
}

interface ButtonIconProps {
  as: any
  size?: "md" 
}

const ButtonIcon:FC<ButtonIconProps> = (props) => {
  return (
    <Icon as={props.as} fontSize={props.size === "md" ? "28px" : "12px"} color="black"/>
  )
}

export default InputButtonIcon