export default [
  {
    type: 1,
    name: "Deer",
    biome: 2
  },
  {
    type: 2,
    name: "Bear",
    biome: 2
  },
  {
    type: 3,
    name: "Tiger",
    biome: 2
  },
  {
    type: 4,
    name: "Gorilla",
    biome: 2
  },
  {
    type: 5,
    name: "Rat (Mouse)",
    biome: 2
  },
  {
    type: 6,
    name: "Frog",
    biome: 2
  },
  {
    type: 7,
    name: "Hedgehog",
    biome: 2
  },
  {
    type: 8,
    name: "Owl",
    biome: 2
  },
  {
    type: 9,
    name: "Moose",
    biome: 2
  },
  {
    type: 10,
    name: "Capybara",
    biome: 2
  },
  {
    type: 11,
    name: "Sheep",
    biome: 1
  },
  {
    type: 12,
    name: "Bison",
    biome: 1
  },
  {
    type: 13,
    name: "Ferret",
    biome: 1
  },
  {
    type: 14,
    name: "Rabbit",
    biome: 1
  },
  {
    type: 15,
    name: "Goat Plain",
    biome: 1
  },
  {
    type: 16,
    name: "Guinea Pig",
    biome: 3
  },
  {
    type: 17,
    name: "Wild Boar",
    biome: 1
  },
  {
    type: 18,
    name: "Gazelle",
    biome: 1
  },
  {
    type: 19,
    name: "Wolf",
    biome: 1
  },
  {
    type: 20,
    name: "Cow",
    biome: 1
  },
  {
    type: 21,
    name: "Pig",
    biome: 1
  },
  {
    type: 22,
    name: "Leopard",
    biome: 3
  },
  {
    type: 23,
    name: "Llama",
    biome: 3
  },
  {
    type: 24,
    name: "Calacal",
    biome: 3
  },
  {
    type: 25,
    name: "Goat Mountain",
    biome: 3
  },
  {
    type: 26,
    name: "Red Fox",
    biome: 3
  },
  {
    type: 27,
    name: "Eagle",
    biome: 3
  },
  {
    type: 28,
    name: "Wild Sheep",
    biome: 3
  },
  {
    type: 29,
    name: "Coyote",
    biome: 3
  },
  {
    type: 30,
    name: "Ilipika",
    biome: 3
  },
]