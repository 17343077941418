import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import DataTable from "react-data-table-component"
import {RotatingLines as LoaderSpinner} from "react-loader-spinner"
import axios from "axios"

import ItemTypes from "../../classes/getTypes/IapEditor/itemTypes"
import InterfaceOffer from "../../typescript/interfaceOffer"

import { Box, Text } from "@chakra-ui/react"

import InputButtonIcon from "../../Components/Inputs/InputButtonIcon"
import InputButton from "../../Components/Inputs/InputButton"
import InputCheckbox from "../../Components/Inputs/InputCheckbox"
import { motion } from "framer-motion"

const Offers = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const itemTypes = new ItemTypes()

  const [data, setData] = useState<InterfaceOffer[]>([])
  
  function getData(){

    setLoading(true)

    axios.get("http://localhost:4000/offers").then((res:any)=>{
      setData(res.data)
      setLoading(false)
    }).catch((err:any)=>{
      console.log(err)
      setLoading(false)
    })

    setLoading(false)
    
  }
  
  useEffect(()=>{
    getData()
  },[])

  const [columns, setColumns] = useState([
    {
      name: "ID",
      selector: (row:any) => row.id,
      sortable: true,
      width: "50px",
      cell: (val:any) => 
        <Text>{val.id}</Text>
    },
    {
      name: "Name",
      selector: (row:any) => row.serviceName,
      sortable: true,
      width: "80px",
      cell: (val:any) => 
        <Text>{val.serviceName}</Text>
    },
    {
      name: "Description",
      selector: (row:any) => row.serviceDescription,
      sortable: true,
      width: "90px",
      cell: (val:any) => 
        <Text>{val.serviceDescription}</Text>
    },
    {
      name: "Priority Order",
      selector: (row:any) => row.priorityOrder,
      sortable: true,
      width: "50px",
      cell: (val:any) => 
        <Text>{val.priorityOrder}</Text>
    },
    {
      name: "Type",
      selector: (row:any) => row.offerType,
      sortable: true,
      width: "70px",
      cell: (val:any) => 
        <Text>{val.offerType === 1 ? "Single Item" : "Bundle"}</Text>
    },
    {
      name: "Active",
      selector: (row:any) => row.isActive,
      sortable: true,
      width: "70px",
      cell: (val:any) => 
        <Box pointerEvents="none">
          <InputCheckbox name="" value={val.isActive} sxDiv={{margin: "0"}} return={()=>null}/>
        </Box>
    },
    {
      name: "1 Time Purchase",
      selector: (row:any) => row.isOneTimePurchase,
      sortable: true,
      width: "70px",
      cell: (val:any) => 
        <Box pointerEvents="none">
          <InputCheckbox name="" value={val.isOneTimePurchase} sxDiv={{margin: "0"}} return={()=>null}/>
        </Box>
    },
    {
      name: "Unlock Level",
      selector: (row:any) => row.unlockLevel,
      sortable: true,
      width: "70px",
      cell: (val:any) => 
        <Text>{val.unlockLevel}</Text>
    },
    {
      name: "Visible on Start Level",
      selector: (row:any) => row.isVisibleOnStartLevel,
      sortable: true,
      width: "70px",
      cell: (val:any) => 
        <Box pointerEvents="none">
          <InputCheckbox name="" value={val.isVisibleOnStartLevel} sxDiv={{margin: "0"}} return={()=>null}/>
        </Box>
    },
    {
      name: "Product Type",
      selector: (row:any) => row.offerData.productType,
      sortable: true,
      width: "120px",
      cell: (val:any) => 
        <Text>{val.offerData.productType === 1 ? "Consumable" : val.offerData.productType}</Text>
    },
    {
      name: "Name Localization Key",
      selector: (row:any) => row.offerData.nameGridlyLocalizationKey,
      sortable: true,
      width: "120px",
      cell: (val:any) => 
        <Text>{val.offerData.nameGridlyLocalizationKey}</Text>
    },
    {
      name: "Description Localization Key",
      selector: (row:any) => row.offerData.descGridlyLocalizationKey,
      sortable: true,
      width: "120px",
      cell: (val:any) => 
        <Text>{val.offerData.descGridlyLocalizationKey}</Text>
    },
    {
      name: "Unique Store Key",
      selector: (row:any) => row.offerData.isUniqueStoreKey,
      sortable: true,
      width: "70px",
      cell: (val:any) => 
        <Box pointerEvents="none">
          <InputCheckbox name="" value={val.offerData.isUniqueStoreKey} sxDiv={{margin: "0"}} return={()=>null}/>
        </Box>
    },
    {
      name: "Product Key",
      selector: (row:any) => row.offerData.storeProductKey,
      sortable: true,
      width: "120px",
      cell: (val:any) => 
        <Text>{val.offerData.storeProductKey}</Text>
    },
    {
      name: "Bundles",
      selector: (row:any) => row.offerData.bundles,
      sortable: true,
      width: "120px",
      cell: (val:any) => 
        <Box display="flex" textAlign="left">
          {
            <Text sx={{"&:not(:last-child)": {mr: "5px"}, "&:not(:last-child)::after": {content: "','"}}}>
              {val.offerData.bundles.length === 0 ? "0 bundle" : val.offerData.bundles.length === 1 ? "1 bundle: " : `${val.offerData.bundles.length} bundles: `}
              {
                val.offerData.bundles.map((bundle:any, i:number)=>
                  `${bundle.id}${val.offerData.bundles.length - 1 !== i ? ", " : ""}`
                )
              }
            </Text>
          }
        </Box>
    },
    {
      name: "Background Name",
      selector: (row:any) => row.offerData.backgroundName,
      sortable: true,
      width: "120px",
      cell: (val:any) => 
        <Text>{val.offerData.backgroundName}</Text>
    },
    {
      name: "Most Popular",
      selector: (row:any) => row.offerData.isMostPopular,
      sortable: true,
      width: "70px",
      cell: (val:any) => 
        <Box pointerEvents="none">
          <InputCheckbox name="" value={val.offerData.isMostPopular} sxDiv={{margin: "0"}} return={()=>null}/>
        </Box>
    },
    {
      name: "Best Value",
      selector: (row:any) => row.offerData.isBestValue,
      sortable: true,
      width: "70px",
      cell: (val:any) => 
        <Box pointerEvents="none">
          <InputCheckbox name="" value={val.offerData.isBestValue} sxDiv={{margin: "0"}} return={()=>null}/>
        </Box>
    },
    {
      name: "Parameters",
      selector: (row:any) => row.offerData.parameters,
      sortable: true,
      width: "100px",
      cell: (val:any) => 
        <Box display="flex" textAlign="left">
          {
            <Text sx={{"&:not(:last-child)": {mr: "5px"}, "&:not(:last-child)::after": {content: "','"}}}>
              {val.offerData.parameters.length === 0 ? "0 parameter" : val.offerData.parameters.length === 1 ? "1 parameter: " : `${val.offerData.parameters.length} parameters: `}
              {
                val.offerData.parameters.map((parameter:any, i:number)=>
                  `${parameter}${val.offerData.parameters.length - 1 !== i ? ", " : ""}`
                )
              }
            </Text>
          }
        </Box>
    },
    {
      name: "Actions",
      selector: (row:any) => row.actions,
      maxWidth: "88px", 
      cell: (val:any) => 
        <>
          <InputButtonIcon icon="edit" sx={{mr: "5px"}} onClick={()=>navigate(`/in-app-purchases-editor/offers/edit-offer/${val.id}`)}/>
          <InputButtonIcon icon="remove" onClick={()=>console.log("remove")}/>
        </>
    },
  ])
  
  return (
    <Box pos="absolute" left="50%" top="50%" sx={{transform: "translate(-50%, -50%)"}}>
      <Box sx={{
        "& > div": {
          overflow: "visible"
        },
        "& div": {
          backgroundColor: "transparent!important",
        },
        "& .rdt_Table": {
          backgroundColor: "transparent",
          color: "#fff"
        },
        "& .rdt_TableHead": {
          height: "40px"
        },
        "& .rdt_TableHeadRow": {
          backgroundColor: "#070707",
          color: "#fff",
          fontWeight: "700",
          fontSize: "10px",
          borderTop: "1px solid #fff",
          borderLeft: "1px solid #fff",
          borderRight: "1px solid #fff",
          borderBottom: "1px solid #fff",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          height: "40px",
          minHeight: "40px",
        },
        "& .rdt_TableCol": {
          borderRight: "1px solid #fff",
          justifyContent: "center",
          px: "5px",
          "&:last-child": {
            borderRight: "0px",
          }
        },
        "& .rdt_TableCol_Sortable": {
          pos: "relative",
          "& span": {
            pos: "absolute",
            right: "0",
          },
          "&:hover": {opacity: 1},
          "&:hover span": {opacity: 1},
        },
        "& .rdt_TableBody": {
          borderLeft: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        "& .rdt_TableRow": {
          backgroundColor: "transparent",
          borderBottom: "1px solid #fff!important",
          color: "#fff",
          height: "40px",
          minHeight: "40px",
          "&:last-child": {
            borderBottom: "0!important",
          }
        },
        "& .rdt_TableCell": {
          borderRight: "1px solid #fff",
          justifyContent: "center",
          fontSize: "10px",
          padding: "0 2.5px",
          height: "40px",
          minHeight: "40px",
          "&:last-child": {
            borderRight: "0px",
          }
        },
        "& .rdt_Pagination": {
          color: "#fff",
          backgroundColor: "transparent",
          borderTop: "1px solid #fff",
          borderLeft: "1px solid #fff",
          borderRight: "1px solid #fff",
          borderBottom: "1px solid #fff",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          "& button": {
            fill: "#fff",
            "&:disabled": {opacity: 0.2}
          }
        },
      }}>
        <DataTable
          columns={columns}
          data={data}
          pagination
          defaultSortFieldId={1}
          progressPending={loading}
          progressComponent={
            <Box w="1730px" display="flex" justifyContent="center" mb="20px">
              <LoaderSpinner width="35px" strokeColor="#fff"/>
            </Box>
          }
          noDataComponent={
            <Box w="1730px" display="flex" justifyContent="center" mb="20px">
              <LoaderSpinner width="35px" strokeColor="#fff"/>
            </Box>
          }
        />
      </Box>

      <InputButton label="Add Offer" loading={loading} bg="#4DC313" sxDiv={{mx: "auto", mt: "20px", w: loading ? "200px" : "100%", transition: "0.1s"}} onClick={()=>navigate("/in-app-purchases-editor/offers/new-offer")}/>
    </Box>
  )
}

export default Offers