import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import DataTable from "react-data-table-component"
import {RotatingLines as LoaderSpinner} from "react-loader-spinner"

import ItemTypes from "../../classes/getTypes/IapEditor/itemTypes"
import InterfaceBundle from "../../typescript/interfaceBundle"

import { Box, Text } from "@chakra-ui/react"

import InputButtonIcon from "../../Components/Inputs/InputButtonIcon"
import InputButton from "../../Components/Inputs/InputButton"
import axios from "axios"

const Bundles = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const itemTypes = new ItemTypes()

  const [columns, setColumns] = useState([
    {
      name: "ID",
      selector: (row:any) => row.id,
      sortable: true,
      width: "155px",
      cell: (val:any) => 
        <Text>{val.id}</Text>
    },
    {
      name: "Name",
      selector: (row:any) => row.name,
      sortable: true,
      width: "180px",
      cell: (val:any) => 
        <Text>{val.name}</Text>
    },
    {
      name: "Items",
      selector: (row:any) => row.bundleData.items,
      sortable: true,
      width: "240px",
      cell: (val:any) => 
        <Box display="flex" textAlign="left">
          {
            <Text sx={{"&:not(:last-child)": {mr: "5px"}, "&:not(:last-child)::after": {content: "','"}}}>
              {val.bundleData?.items.length === 0 ? "0 item" : val.bundleData?.items.length === 1 ? "1 item: " : `${val.bundleData?.items.length} items: `}
              {
                val.bundleData?.items.map((item:any, i:number)=>
                  `${itemTypes.getOne(item.gameItem.id).name}${val.bundleData?.items.length - 1 !== i ? ", " : ""}`
                )
              }
            </Text>
          }
        </Box>
    },
    {
      name: "Actions",
      selector: (row:any) => row.action,
      maxWidth: "88px", 
      cell: (val:any) => 
        <>
          <InputButtonIcon icon="edit" sx={{mr: "5px"}} onClick={()=>navigate(`/in-app-purchases-editor/bundles/edit-bundle/${val.id}`)}/>
          <InputButtonIcon icon="remove" onClick={()=>console.log("remove")}/>
        </>
    },
  ])
  
  const [data, setData] = useState<InterfaceBundle[]>([])
  
  function getData(){

    setLoading(true)

    setTimeout(() => {
      axios.get("http://localhost:4000/bundles").then((res:any)=>{
        setData(res.data)
        setLoading(false)
      }).catch((err:any)=>{
        console.log(err)
        setLoading(false)
      })
    }, 500);
    
  }
  
  useEffect(()=>{
    getData()
  },[])
  
  return (
    <Box pos="absolute" left="50%" top="50%" sx={{transform: "translate(-50%, -50%)"}}>
      <Box sx={{
        "& > div": {
          overflow: "visible"
        },
        "& div": {
          backgroundColor: "transparent!important",
        },
        "& .rdt_Table": {
          backgroundColor: "transparent",
          color: "#fff"
        },
        "& .rdt_TableHead": {
          height: "40px"
        },
        "& .rdt_TableHeadRow": {
          backgroundColor: "#070707",
          color: "#fff",
          fontWeight: "700",
          fontSize: "10px",
          borderTop: "1px solid #fff",
          borderLeft: "1px solid #fff",
          borderRight: "1px solid #fff",
          borderBottom: "1px solid #fff",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          height: "40px",
          minHeight: "40px",
        },
        "& .rdt_TableCol": {
          borderRight: "1px solid #fff",
          justifyContent: "center",
          "&:last-child": {
            borderRight: "0px",
          }
        },
        "& .rdt_TableCol_Sortable": {
          pos: "relative",
          "& span": {
            pos: "absolute",
            right: "0",
          },
          "&:hover": {opacity: 1},
          "&:hover span": {opacity: 1},
        },
        "& .rdt_TableBody": {
          borderLeft: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        "& .rdt_TableRow": {
          backgroundColor: "transparent",
          borderBottom: "1px solid #fff!important",
          color: "#fff",
          height: "40px",
          minHeight: "40px",
          "&:last-child": {
            borderBottom: "0!important",
          }
        },
        "& .rdt_TableCell": {
          borderRight: "1px solid #fff",
          justifyContent: "center",
          fontSize: "10px",
          padding: "0 2.5px",
          height: "40px",
          minHeight: "40px",
          "&:last-child": {
            borderRight: "0px",
          }
        },
        "& .rdt_Pagination": {
          color: "#fff",
          backgroundColor: "transparent",
          borderTop: "1px solid #fff",
          borderLeft: "1px solid #fff",
          borderRight: "1px solid #fff",
          borderBottom: "1px solid #fff",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          "& button": {
            fill: "#fff",
            "&:disabled": {opacity: 0.2}
          }
        },
      }}>
        <DataTable
          columns={columns}
          data={data}
          pagination
          defaultSortFieldId={1}
          progressPending={loading}
          progressComponent={
            <Box w="677px" display="flex" justifyContent="center" mb="20px">
              <LoaderSpinner width="35px" strokeColor="#fff"/>
            </Box>
          }
          noDataComponent={
            <Box w="677px" display="flex" justifyContent="center" mb="20px">
              <LoaderSpinner width="35px" strokeColor="#fff"/>
            </Box>
          }
        />
      </Box>

      <InputButton label="Add Bundle" loading={loading} bg="#4DC313" sxDiv={{mx: "auto", mt: "20px", w: loading ? "200px" : "100%", transition: "0.1s"}} onClick={()=>navigate("/in-app-purchases-editor/bundles/new-bundle")}/>
    </Box>
  )
}

export default Bundles