import {useEffect, useState} from "react"

import { useSelector, useDispatch } from "react-redux"
import { changeMaxAnimals, addNewSpawner, removeSavedSpawner } from "../../../../redux/levelSlice"
import { changeClipboard } from "../../../../redux/editorSlice"
import InterfaceAllSlices from "../../../../typescript/interfaceAllSlices"
import InterfaceSpawner from "../../../../typescript/interfaceSpawner"

import Spawner from "../../../../classes/getFromRedux/LevelEditor/Spawner"

import {Box, Image, Heading, Text, Button, Icon} from "@chakra-ui/react"
import { motion, AnimatePresence } from "framer-motion"
import {IoCloseSharp as CloseIcon, IoPencilOutline as EditIcon} from "react-icons/io5"
import {FaRegClone as CloneIcon} from "react-icons/fa"

import InputSlider from "../../../Inputs/InputSlider"
import InputNumber from "../../../Inputs/InputNumber"
import InputButton from "../../../Inputs/InputButton"
import InputSelectItem from "../../../Inputs/InputSelectItem"
import InputSelectText from "../../../Inputs/InputSelectText"

import spawnerStoneImg from "../../../../assets/images/spawners/spawner.png"

const Spawners = () => {

  const spawner = new Spawner()

  const userStates = useSelector((state:InterfaceAllSlices) => state.user)
  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  const [stoneRatios, setStoneRatios] = useState({
    blueStoneRatio: 20,
    yellowStoneRatio: 20,
    redStoneRatio: 20,
    purpleStoneRatio: 20,
    greenStoneRatio: 20,
  })
  const [animals, setAnimals] = useState({
    animal1: {yielderType:0,count:0},
    animal3: {yielderType:0,count:0},
    animal2: {yielderType:0,count:0},
  })
  const [animalChance, setAnimalChance] = useState(50)

  function availablePersentage(){
     
    let availablePersentage = 100

    Object.values(stoneRatios).map((stoneRatio,i)=>{
      availablePersentage -= stoneRatio
    })

    return availablePersentage
  }

  useEffect(()=>{
    availablePersentage()
  },[stoneRatios])

  function changeMaxAnimalsFunc(val:number){
    dispatch(changeMaxAnimals({
      maxAnimals: val, 
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function addNewSpawnerFunc(){
    dispatch(addNewSpawner({
      gameElementType: 8192,
      spawnerType: 8192,
      blueStoneRatio: stoneRatios.blueStoneRatio,
      yellowStoneRatio: stoneRatios.yellowStoneRatio,
      redStoneRatio: stoneRatios.redStoneRatio,
      purpleStoneRatio: stoneRatios.purpleStoneRatio,
      greenStoneRatio: stoneRatios.greenStoneRatio,
      animalChance: animalChance,
      animal1Type: animals.animal1.yielderType,
      animal2Type: animals.animal2.yielderType,
      animal3Type: animals.animal3.yielderType,
      animal1Count: animals.animal1.count,
      animal2Count: animals.animal2.count,
      animal3Count: animals.animal3.count,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function selectSpawnerFunc(e:any){

    const dataset = e.target.closest("#spawner").dataset
    
    dispatch(changeClipboard({
      target: 0,
      gameElementType: Number(dataset.gameelementtype),
      spawnerType: Number(dataset.spawnertype),
      blueStoneRatio: Number(dataset.bluestoneratio),
      yellowStoneRatio: Number(dataset.yellowstoneratio),
      redStoneRatio: Number(dataset.redstoneratio),
      purpleStoneRatio: Number(dataset.purplestoneratio),
      greenStoneRatio: Number(dataset.greenstoneratio),
      animalChance: Number(dataset.animalchance),
      animal1Type: Number(dataset.animal1type),
      animal2Type: Number(dataset.animal2type),
      animal3Type: Number(dataset.animal3type),
      animal1Count: Number(dataset.animal1count),
      animal2Count: Number(dataset.animal2count),
      animal3Count: Number(dataset.animal3count),
    }))
  }

  function removeSpawnerFunc(i:number){
    dispatch(removeSavedSpawner({
      value: i,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }))
  }

  function cloneSpawnerFunc(e:any){
    
    const dataset = e.target.closest("#spawner").dataset

    setStoneRatios({
      blueStoneRatio: Number(dataset.bluestoneratio),
      yellowStoneRatio: Number(dataset.yellowstoneratio),
      redStoneRatio: Number(dataset.redstoneratio),
      purpleStoneRatio: Number(dataset.purplestoneratio),
      greenStoneRatio: Number(dataset.greenstoneratio),
    })
    setAnimals({
      animal1: {yielderType: Number(dataset.animal1type), count: Number(dataset.animal1count)},
      animal2: {yielderType: Number(dataset.animal2type), count: Number(dataset.animal2count)},
      animal3: {yielderType: Number(dataset.animal3type), count: Number(dataset.animal3count)},
    })
    setAnimalChance(Number(dataset.animalchance))
    
  }
  
  return (
    <Box pt="100px" mx="auto" w="84%">
      <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" m="0 auto 40px auto" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
        <Image src={spawnerStoneImg} w="52px" h="52px"/>
      </Box>

      <Box display="flex" flexDir="column" flexWrap="wrap" w="100%" mx="auto">
        <InputSlider name="" sxDiv={{w:"100%", m: "0 auto 20px auto"}} img={spawner.getOne(2).image} min={0} max={stoneRatios.blueStoneRatio+availablePersentage()} value={stoneRatios.blueStoneRatio} return={(dataInput)=>setStoneRatios({...stoneRatios, blueStoneRatio:dataInput.value})}/>
        <InputSlider name="" sxDiv={{w:"100%", m: "0 auto 20px auto"}} img={spawner.getOne(4).image} min={0} max={stoneRatios.yellowStoneRatio+availablePersentage()} value={stoneRatios.yellowStoneRatio} return={(dataInput)=>setStoneRatios({...stoneRatios, yellowStoneRatio:dataInput.value})}/>
        <InputSlider name="" sxDiv={{w:"100%", m: "0 auto 20px auto"}} img={spawner.getOne(8).image} min={0} max={stoneRatios.redStoneRatio+availablePersentage()} value={stoneRatios.redStoneRatio} return={(dataInput)=>setStoneRatios({...stoneRatios, redStoneRatio:dataInput.value})}/>
        <InputSlider name="" sxDiv={{w:"100%", m: "0 auto 20px auto"}} img={spawner.getOne(16).image} min={0} max={stoneRatios.purpleStoneRatio+availablePersentage()} value={stoneRatios.purpleStoneRatio} return={(dataInput)=>setStoneRatios({...stoneRatios, purpleStoneRatio:dataInput.value})}/>
        <InputSlider name="" sxDiv={{w:"100%", m: "0 auto 0 auto"}} img={spawner.getOne(32).image} min={0} max={stoneRatios.greenStoneRatio+availablePersentage()} value={stoneRatios.greenStoneRatio} return={(dataInput)=>setStoneRatios({...stoneRatios, greenStoneRatio:dataInput.value})}/>
      </Box>

      <Box m="40px 0">
        <InputNumber name="" label="Animal Chance" warning="Value as persentage (%)" sx={{w:"100px",m:0}} sxDiv={{w:"100%"}} min={0} max={100} value={animalChance} return={(dataInput)=>setAnimalChance(dataInput.value)}/>
      </Box>

      <Box display="flex" flexDir="column">
        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectItem name="" sxDiv={{m: "0 20px 0 0"}} label="Animal 1" biggerLabel placeholder="Select..." value={spawner.getOne(2048, animals.animal1.yielderType).name} dropdownItems={spawner.getByBiome(levelStates.biomeType)} return={(dataInput)=>{setAnimals({...animals, animal1: {yielderType: dataInput.value.yieldertype, count: 1}})}}/>
          <AnimatePresence>
            {
              animals.animal1.yielderType && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber name="" sxDiv={{m:"0"}} label="Count" value={animals.animal1.count} return={(dataInput)=>dataInput.value > 0 ? setAnimals({...animals, animal1: {...animals.animal1, count: dataInput.value}}) : setAnimals({...animals, animal1: {yielderType: 0, count: dataInput.value}})}/></Box>
            }
          </AnimatePresence>
        </Box>

        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectItem name="" sxDiv={{m: "0 20px 0 0"}} label="Animal 2" biggerLabel placeholder="Select..." value={spawner.getOne(2048, animals.animal2.yielderType).name} dropdownItems={spawner.getByBiome(levelStates.biomeType)} return={(dataInput)=>{setAnimals({...animals, animal2: {yielderType: dataInput.value.yieldertype, count: 1}})}}/>
          <AnimatePresence>
            {
              animals.animal2.yielderType && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber name="" sxDiv={{m:"0"}} label="Count" value={animals.animal2.count} return={(dataInput)=>dataInput.value > 0 ? setAnimals({...animals, animal2: {...animals.animal2, count: dataInput.value}}) : setAnimals({...animals, animal2: {yielderType: 0, count: dataInput.value}})}/></Box>
            }
          </AnimatePresence>
        </Box>

        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectItem name="" sxDiv={{m: "0 20px 0 0"}} label="Animal 3" biggerLabel placeholder="Select..." value={spawner.getOne(2048, animals.animal3.yielderType).name} dropdownItems={spawner.getByBiome(levelStates.biomeType)} return={(dataInput)=>{setAnimals({...animals, animal3: {yielderType: dataInput.value.yieldertype, count: 1}})}}/>
          <AnimatePresence>
            {
              animals.animal3.yielderType && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber name="" sxDiv={{m:"0"}} label="Count" value={animals.animal3.count} return={(dataInput)=>dataInput.value > 0 ? setAnimals({...animals, animal3: {...animals.animal3, count: dataInput.value}}) : setAnimals({...animals, animal3: {yielderType: 0, count: dataInput.value}})}/></Box>
            }
          </AnimatePresence>
        </Box>
      </Box>

      <Box m="40px 0">
        <InputNumber name="" label="Max Animal" warning="*Number of maximum available animal on the screen at the same time. This effects whole level." sx={{w:"100px",m:0}} sxDiv={{w:"100%"}}  min={0} value={levelStates.maxAnimals} return={(dataInput)=>changeMaxAnimalsFunc(dataInput.value)}/>
      </Box>

      <Box display="flex" flexDir="column">
        <Box mb="10px">
          <Heading as="h5" fontSize="12px" fontWeight="600">Saved Spawners</Heading>
        </Box>

        <Box mb="10px">
          <InputButton label="Add New" bg="transparent" hoverBg="#fff" hoverColor="#000" width="100%" margin="0" border="1px solid #fff" onClick={()=>addNewSpawnerFunc()}/>
        </Box>

        <Box display="flex" flexDir="column" pb="25px">
          {
            levelStates.savedSpawners?.map((savedSpawner,i)=>
              <Box key={i} as={motion.div} initial={{translateY: -20, opacity: 0}} animate={{translateY: 0, opacity: 1}} exit={{translateY: 20, opacity: 0}} display="flex" flexDir="row" alignItems="center" border="1px solid #fff" borderRadius="8px" p="16px" mb="10px" pos="relative"
                id="spawner" data-gameelementtype={savedSpawner.gameElementType} data-spawnertype={savedSpawner.spawnerType} data-bluestoneratio={savedSpawner.blueStoneRatio} data-yellowstoneratio={savedSpawner.yellowStoneRatio} data-redstoneratio={savedSpawner.redStoneRatio} data-purplestoneratio={savedSpawner.purpleStoneRatio} data-greenstoneratio={savedSpawner.greenStoneRatio} data-animalchance={savedSpawner.animalChance} data-animal1type={savedSpawner.animal1Type} data-animal2type={savedSpawner.animal2Type} data-animal3type={savedSpawner.animal3Type} data-animal1count={savedSpawner.animal1Count} data-animal2count={savedSpawner.animal2Count} data-animal3count={savedSpawner.animal3Count}
              >

                <Box position="absolute" top="0" right="0" display="flex" flexDir="column" padding="5px">
                  <Button padding="5px" w="20px" h="20px" size="xs" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>removeSpawnerFunc(i)}>
                    <Icon as={CloseIcon}/>
                  </Button>

                  <Button padding="5px" w="20px" h="20px" size="xs" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={(e)=>cloneSpawnerFunc(e)}>
                    <Icon as={CloneIcon}/>
                  </Button>
                </Box>
                
                <Box w="72px" h="72px" minW="72px" border="1px solid #fff" borderRadius="8px" mr="15px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none" cursor="pointer" onClick={(e)=>selectSpawnerFunc(e)}>
                  <Image src={spawnerStoneImg} w="42px" h="42px"/>
                </Box>
                
                <Box display="flex" flexDir="row" flexWrap="wrap" userSelect="none">

                  <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(2).image}`} h="30px" w="30px"/>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">%{savedSpawner.blueStoneRatio}</Text>
                    </Box>
                  </Box>
                          
                  <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(4).image}`} h="30px" w="30px"/>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">%{savedSpawner.yellowStoneRatio}</Text>
                    </Box>
                  </Box>                  
                  
                  <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(8).image}`} h="30px" w="30px"/>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">%{savedSpawner.redStoneRatio}</Text>
                    </Box>
                  </Box>
                  
                  <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(16).image}`} h="30px" w="30px"/>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">%{savedSpawner.purpleStoneRatio}</Text>
                    </Box>
                  </Box>
                  
                  <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(32).image}`} h="30px" w="30px"/>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">%{savedSpawner.greenStoneRatio}</Text>
                    </Box>
                  </Box>

                  <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                    <Box display="flex" justifyContent="center" alignItems="center" m="auto">
                      <Text fontSize="18px" fontWeight="700">AC</Text>
                    </Box>
                    <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                      <Text fontSize="11px" fontWeight="600">%{savedSpawner.animalChance}</Text>
                    </Box>
                  </Box>
                  
                  {
                    savedSpawner.animal1Count > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(2048,savedSpawner.animal1Type).image}`} h="30px" w="30px"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="11px" fontWeight="600">{savedSpawner.animal1Count}</Text>
                      </Box>
                    </Box>
                  }

                  {
                    savedSpawner.animal2Count > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(2048,savedSpawner.animal2Type).image}`} h="30px" w="30px"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="11px" fontWeight="600">{savedSpawner.animal2Count}</Text>
                      </Box>
                    </Box>
                  }

                  {
                    savedSpawner.animal3Count > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${spawner.getOne(2048,savedSpawner.animal3Type).image}`} h="30px" w="30px"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="33px" h="20px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="11px" fontWeight="600">{savedSpawner.animal3Count}</Text>
                      </Box>
                    </Box>
                  }
                </Box>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

export default Spawners