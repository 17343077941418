import React, { FC, ReactNode, useEffect } from "react"
import { useLocation } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"
import { changeSidebar } from "../../redux/editorSlice"

import {Box} from "@chakra-ui/react"

import Header from "./Header"
import LeftSidebar from "./LeftSidebar"
import RightSidebar from "./RightSidebar"

import PopupNewLevel from "../Popups/PopupNewLevel"
import PopupError from "../Popups/PopupError"
import PopupDeleteValidation from "../Popups/PopupDeleteValidation"

import sidebar from "../../assets/fakeData/sidebar"

interface Props {
  page: ReactNode
  header?: boolean
  leftSidebar?: boolean
  rightSidebar?: boolean
}

const Layout:FC<Props> = (props) => {

  const location = useLocation()
  const editorStates = useSelector((state:any) => state.editor)
  const popupStates = useSelector((state:any) => state.popupManager)
  const dispatch = useDispatch()

  useEffect(()=>{
    if(location.pathname === "/level-editor" && editorStates.sidebar !== "Notifications"){
      dispatch(changeSidebar("Notifications"))
    }
    
    if(location.pathname.split("/level-editor")[3]?.split("-")[1] === "level" && editorStates.sidebar !== sidebar[0].menuItems[0]){
      dispatch(changeSidebar(sidebar[0].menuItems[0]))
    }
  },[location.pathname])
  
  return (
    <Box>
      {
        props.header && <Header/>
      }

      <Box position="relative" maxW="1920px" h="100vh" margin="auto" pt="60px" display="flex" justifyContent="space-between" 
        borderLeft={(props.rightSidebar && !props.leftSidebar) ? "1px solid #1E2D3D" : ""} 
        borderRight={(!props.rightSidebar && props.leftSidebar) ? "1px solid #1E2D3D" : ""} 
        borderBottom={props.rightSidebar || props.leftSidebar ? "1px solid #1E2D3D" : ""}>

        {
          props.leftSidebar && <LeftSidebar/>
        }

        <Box w={`calc(1920px - (${props.leftSidebar ? "390px" : "0px"} + ${props.rightSidebar ? "670px" : "0px"}))`}>
          {
            props.page
          }
        </Box>

        {
          props.rightSidebar && <RightSidebar/>
        }
        
      </Box>

      {
        popupStates.popupNewLevel.status && <PopupNewLevel/>
      }

      {
        popupStates.popupError.status && <PopupError/>
      }

      {
        popupStates.popupDelete.status && <PopupDeleteValidation/>
      }
    </Box>
  )
}

export default Layout