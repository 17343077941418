import biomePlain from "../../../assets/images/bioms/plain.png"
import biomeForest from "../../../assets/images/bioms/forest.png"
import biomeMountain from "../../../assets/images/bioms/mountain.png"
import biomeAquatic from "../../../assets/images/bioms/aquatic.png"
import biomeArctic from "../../../assets/images/bioms/arctic.png"
import biomeDesert from "../../../assets/images/bioms/desert.png"

export default [
  {
    type: 0,
    name: "All",
    img: null,
    status: true,
  },
  {
    type: 1,
    name: "Plain",
    img: biomePlain,
    status: true,
  },
  {
    type: 2,
    name: "Forest",
    img: biomeForest,
    status: true,
  },
  {
    type: 3,
    name: "Mountain",
    img: biomeMountain,
    status: true,
  },
  {
    type: 4,
    name: "Aquaitic",
    img: biomeAquatic,
    status: false,
  },
  {
    type: 5,
    name: "Arctic",
    img: biomeArctic,
    status: false,
  },
  {
    type: 6,
    name: "Desert",
    img: biomeDesert,
    status: false,
  },
]