import { FC, useEffect, useRef, useState } from "react"

import {Box, Text, Icon, Image, SystemStyleObject} from "@chakra-ui/react"
import {BsChevronDown} from "react-icons/bs"
import {motion} from "framer-motion"

interface Props {
  name: string
  loading?: boolean
  label?: string
  biggerLabel?: boolean
  placeholder?: string
  sxDiv?: SystemStyleObject
  sx?: SystemStyleObject
  sxDropdown?: SystemStyleObject
  dropdownItemsGrid?: 2 | 3 | 4
  dropdownItems: {id?:number | string, type?: number, yielderType?: number, blockerState?: number, biomeType?: number, colorType?: number, name?: string, image: string}[]
  value?: string | number
  return?: (data:{name:string, value:{id:number | string, type: number, yieldertype: number, blockerstate: number, biometype: number, colortype: number, name: string, image: string}}) => void
}

const InputSelectItem:FC<Props> = (props) => {

  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const dropdownRef:any = useRef()

  function onClickInput(value:any){

    const data = {
      name: props.name,
      value: value.target.dataset
    }
    props.return!(data)
    setShowDropdown(false)
  }

  useEffect(()=>{
    document.body.addEventListener("click", (e)=>{           
      let result = false

      for (let i = 0; i < dropdownRef.current.getElementsByTagName("*").length; i++) {
        if(dropdownRef.current.getElementsByTagName("*")[i] === e.target){
          result = true
          break
        }
      }
      
      if(result === false){
        setShowDropdown(false)
      }
    })
  },[])
  
  return (
    <Box position="relative" sx={props.sxDiv} width="270px" margin="0 0 20px 0" ref={dropdownRef}>
      
      {
        props.label && !props.biggerLabel ? 
          <Text fontSize="12px" fontWeight="600" marginBottom="7.5px">{props.label}</Text> 
          : props.label && props.biggerLabel && 
          <Text fontSize="16px" fontWeight="600" marginBottom="7.5px" color="#607B96" userSelect="none">{props.label}</Text>
      }
      
      <Box dataset-name={props.name} display="flex" alignItems="center" padding="0 10px" position="relative" overflow="hidden" width="100%" height="35px" border="1px solid #fff" borderRadius="8px" opacity={props.loading ? 0.4 : 1} cursor={props.loading ? "not-allowed" : "pointer"} sx={props.sx} onClick={()=> !props.loading && setShowDropdown(!showDropdown)}>
        <Box pos="absolute" right="0" top="0" w="100%" h="100%" boxShadow="inset -60px 0 25px -20px #171717" zIndex={2}/>
        <Text userSelect="none" whiteSpace="nowrap" >{props.loading ? "Loading..." : !(typeof props.value === "number" && props.value === 0 || props.value) ? props.placeholder : props.value}</Text>
        <Icon as={BsChevronDown} position="absolute" right="10px" top="50%" transform="translateY(-50%)" zIndex={3} style={{transform: showDropdown ? "rotate(180deg) translateY(50%)" : ""}} transition="200ms"/>
      </Box>
      
      {
        showDropdown &&
        <>
          <Box as={motion.div} initial={{translateY: 20}} animate={{translateY: 0}} position="absolute" top={props.label ? "75px" : "45px"} maxH="40vh" overflow="auto" zIndex="5" backgroundColor="#171717" width="100%" borderRadius="10px" userSelect="none" display="flex" flexWrap="wrap" padding="14px" border="1px solid #fff" sx={props.sxDropdown}>
            {
              props.dropdownItems.map((dropdownItem,i)=>
                <Box key={i} width={props.dropdownItemsGrid === 2 ? "50%" : props.dropdownItemsGrid === 3 ? "33.33%" : props.dropdownItemsGrid === 4 ? "25%" : "33.33%"} padding="4px" display="flex" sx={{"&:nth-of-type(odd)": {justifyContent: props.dropdownItemsGrid === 2 ? "end" : "center"}, "&:nth-of-type(even)" : {justifyContent: props.dropdownItemsGrid === 2 ? "start" : "center"}}}>
                  <Box display="flex" justifyContent="center" border="1px solid #fff" borderRadius="8px" cursor="pointer" p="16px" sx={{aspectRatio: "1"}} 
                    data-id={dropdownItem.id} data-type={dropdownItem.type} data-yieldertype={dropdownItem.yielderType} data-blockerstate={dropdownItem.blockerState} data-biometype={dropdownItem.biomeType} data-colortype={dropdownItem.colorType} onClick={(e)=>onClickInput(e)}
                  >
                    <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${dropdownItem.image}`} w="40px" h="40px" pointerEvents="none" as={motion.img} initial={{scale:0}} animate={{scale:1}}/>
                  </Box>
                </Box>
              )
            }
          </Box>
        </>
      }
    </Box>
  )
}

export default InputSelectItem