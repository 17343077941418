import { useSelector, useDispatch } from "react-redux"
import { changeClipboard } from "../../../../redux/editorSlice"
import InterfaceAllSlices from "../../../../typescript/interfaceAllSlices"

import {Box} from "@chakra-ui/react"

import InputSelectItem from "../../../Inputs/InputSelectItem"
import Blocker from "../../../../classes/getFromRedux/LevelEditor/Blocker"

const Blockers = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const dispatch = useDispatch()

  const blocker = new Blocker()
  
  return (
    <Box pt="100px" w="fit-content" mx="auto">
      <InputSelectItem name="" placeholder="Humanoid Cover Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getHumanoidCoverBlockers()} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)})}))}
      />
      <InputSelectItem name="" placeholder="Humanoid Breakable Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getHumanoidBreakableBlockers()} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)})}))}
      />
      <InputSelectItem name="" placeholder="Breakable Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getBreakableBlockers(levelStates.biomeType)} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)})}))}
      />
      <InputSelectItem name="" placeholder="Biome Specific Cover Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getBiomeSpecificCoverBlockers(levelStates.biomeType)} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)})}))}
      />
      <InputSelectItem name="" placeholder="Biome Essence Collectible Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getBiomeEssenceCollectibleBlockers(levelStates.biomeType)} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)})}))}
      />
      <InputSelectItem name="" placeholder="Color Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getColorBlockers(levelStates.biomeType)} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)}), ...(dataInput.value.colortype && {colorType: Number(dataInput.value.colortype)})}))}
      />
      <InputSelectItem name="" placeholder="Reinforced Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getReinforcedBlockers(levelStates.biomeType)} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)})}))}
      />
      <InputSelectItem name="" placeholder="Rainbow Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getRainbowBlockers(levelStates.biomeType)} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)}), ...(dataInput.value.colortype && {colorType: Number(dataInput.value.colortype)})}))}
      />
      <InputSelectItem name="" placeholder="Regen Blockers" dropdownItemsGrid={3} dropdownItems={blocker.getRegenBlockers(levelStates.biomeType)} 
        return={(dataInput)=>dispatch(changeClipboard({target: 2, ...(dataInput.value.type && {type: Number(dataInput.value.type)}), ...(dataInput.value.blockerstate && {blockerState: Number(dataInput.value.blockerstate)}), ...(dataInput.value.biometype && {biomeType: Number(dataInput.value.biometype)})}))}
      />
    </Box>
  )
}

export default Blockers