import { Navigate, useSearchParams } from "react-router-dom"

import { useDispatch } from "react-redux"
import { logout } from "../redux/userSlice"

const Logout = () => {

  const dispatch = useDispatch()
  dispatch(logout())

  const [searchParams, setSearchParams] = useSearchParams()
  const returnUrl = searchParams.get("returnUrl")
  
  return (
    <Navigate to={returnUrl ? `/login?returnUrl=${returnUrl}` : "/login"}/>
  )
}

export default Logout