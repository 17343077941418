import React, { FC } from "react"
import { Box } from "@chakra-ui/react"
import "./style.css"

interface Props {
  activeLabel: string
  deactiveLabel: string,
  name: string
  value: boolean,
  return?: (data:{name:string, value:boolean}) => void
}

const InputSwitch:FC<Props> = (props:Props) => {

  function onChangeInput(value:any){

    const data = {
      name: props.name,
      value: value
    }
    props.return!(data)
  }
  
  return (
    <Box marginBottom="20px">
      <label className='switch'>
        <input type="checkbox" checked={props.value} name={props.name} onChange={(e)=>onChangeInput(e.target.checked)}/>
        <span className='slider'/>
        <p className='label activeLabel'>{props.activeLabel}</p>
        <p className='label deactiveLabel'>{props.deactiveLabel}</p>
      </label>
    </Box>
  )
}

export default InputSwitch