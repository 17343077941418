import itemTypesList from "../../lists/IapEditor/itemTypesList"
import InterfaceItemType from "../../../typescript/interfaceItemType"

class ItemTypes {

  getAll(){
    return itemTypesList
  }

  getOne(id:number){

    let data:InterfaceItemType[] | any
    
    if((typeof id === "number" && id === 0) || id){
      itemTypesList.map((item)=>{
        if(item.id === id){
          data = item
        }
      })
    }

    return data
  }

  

}

export default ItemTypes