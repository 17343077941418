import InterfaceBlocker from "../../../typescript/interfaceBlocker"

function getBlockers(){
  const allStates = localStorage.getItem("persist:root")
  
  if(allStates){ 
    const parsedAllStates = JSON.parse(allStates)
    const parsedBlockers = JSON.parse(parsedAllStates.blockers)
    const blockers = parsedBlockers.blockers
    return blockers
  } else{
    return null
  }
}

class Blocker{

  getAll(){
    return getBlockers()
  }

  getById(id:string){
    
    let data:InterfaceBlocker[] | any
    
    getBlockers()?.map((item:InterfaceBlocker)=>{

      if(id === item.id){
        data = item
      }

    })

    return data
  }

  getOne(type:number,colorType:number,blockerState:number,biomeType:number){
    
    let data:InterfaceBlocker[] | any
    
    getBlockers()?.map((item:InterfaceBlocker)=>{

      if(type === item.type && (typeof colorType === "number" && colorType === item.colorType || !colorType) && blockerState === item.blockerState && biomeType === item.biomeType){
        data = item
      }

    })

    return data
  }

  getHumanoidCoverBlockers(){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 33554432){
        data.push(item)
      }
    })

    return data
  }

  getHumanoidBreakableBlockers(){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 67108864){
        data.push(item)
      }
    })

    return data
  }

  getBreakableBlockers(biomeType:number){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 524288 && biomeType === item.biomeType){
        data.push(item)
      }
    })

    return data
  }

  getBiomeSpecificCoverBlockers(biomeType:number){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 1048576 && biomeType === item.biomeType){
        data.push(item)
      }
    })

    return data
  }

  getBiomeEssenceCollectibleBlockers(biomeType:number){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 134217728 && biomeType === item.biomeType){
        data.push(item)
      }
    })

    return data
  }

  getColorBlockers(biomeType:number){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 268435456 && biomeType === item.biomeType){
        data.push(item)
      }
    })

    return data
  }

  getReinforcedBlockers(biomeType:number){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 536870912 && biomeType === item.biomeType){
        data.push(item)
      }
    })

    return data
  }

  getRainbowBlockers(biomeType:number){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 1073741824 && biomeType === item.biomeType){
        data.push(item)
      }
    })

    return data
  }

  getRegenBlockers(biomeType:number){
    
    const data:InterfaceBlocker[] | any = []

    getBlockers()?.map((item:InterfaceBlocker)=>{
      if(item.type === 2147483648 && biomeType === item.biomeType){
        data.push(item)
      }
    })

    return data
  }

}

export default Blocker