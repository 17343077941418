import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import axios from "axios"

import InterfaceOffer from "../../typescript/interfaceOffer"
import InterfaceBundle from "../../typescript/interfaceBundle"

import ItemTypes from "../../classes/getTypes/IapEditor/itemTypes"

import { Box, Flex, Text } from "@chakra-ui/react"
import { toast } from "react-toastify"

import InputButtonIcon from "../../Components/Inputs/InputButtonIcon"
import InputSelectText from "../../Components/Inputs/InputSelectText"
import InputText from "../../Components/Inputs/InputText"
import InputNumber from "../../Components/Inputs/InputNumber"
import { motion } from "framer-motion"
import InputTextarea from "../../Components/Inputs/InputTextarea"
import InputCheckbox from "../../Components/Inputs/InputCheckbox"

const Bundle = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const page = location.pathname.split("/")[2] //blockers or spawners
  const newOrEdit = location.pathname.split("/")[3].split("-")[0] //new or edit
  const {offerId} = useParams()
  const [inputsLoading, setInputsLoading] = useState(false)
  const [buttonLoading, setButtonsLoading] = useState({
    actionButtons: false,
    backButton: false,
  })
  
  const itemTypes = new ItemTypes()
  
  const [data, setData] = useState<InterfaceOffer>({
    id: 0,
    serviceName: "",
    serviceDescription: "",
    priorityOrder: 0,
    offerType: 0,
    isActive: false,
    isOneTimePurchase: false,
    unlockLevel: 0,
    isVisibleOnStartLevel: false,
    offerData: {
      productType: 0,
      nameGridlyLocalizationKey: "",
      descGridlyLocalizationKey: "",
      isUniqueStoreKey: false,
      storeProductKey: "",
      bundles: [],
      backgroundName: "",
      isMostPopular: false,
      isBestValue: false,
      parameters: [],
    }
  })

  function getData(){
    
    let localData:any
    setInputsLoading(true)
    setButtonsLoading({
      actionButtons: true,
      backButton: false
    })

    axios.get(`http://localhost:4000/offers/${offerId}`).then((res:any)=>{
      setData(res.data)
      setInputsLoading(false)
      setButtonsLoading({
        actionButtons: false,
        backButton: false
      })
    }).catch((err:any)=>{
      console.log(err)
      setInputsLoading(false)
      setButtonsLoading({
        actionButtons: false,
        backButton: false
      })
      navigate("/in-app-purchases-editor/offers")
    })
    
  }

  function addBundle(){

    axios.get("http://localhost:4000/bundles").then((res)=>{
      setData(prev=>({...prev, offerData: {...prev.offerData, bundles: [...prev.offerData.bundles, res.data[0]]}}))
    }).catch((err)=>{
      console.log(err)
    })

    // IF BUNDLES NEED TO BE UNIQUE
    // const currentBundles:InterfaceBundle[] = [...data.offerData.bundles]
    // const currentBundlesIds:number[] = []
    // const allBundlesIds:number[] = []

    // for (let i = 0; i < currentBundles.length; i++) {
    //   currentBundlesIds.push(currentBundles[i].id)
    // }

    // axios.get("http://localhost:4000/bundles").then((res)=>{

    //   for (let i = 0; i < res.data.length; i++) {
    //     allBundlesIds.push(res.data[i].id)
    //   }
    
    //   for (let i = 0; i < allBundlesIds.length; i++) {
    //     if(!currentBundlesIds.includes(res.data[i].id)){
    //       setData(prev=>({...prev, offerData: {...prev.offerData, bundles: [...prev.offerData.bundles, res.data[i]]}}))
    //     } 
    //   }
      
    // })
    
  }

  function deleteBundle(i:number){

    const currentBundles = [...data.offerData.bundles]

    currentBundles.splice(i,1)

    setData(prev=>({...prev, offerData: {...prev.offerData, bundles: [...currentBundles]}}))
    
  }

  function changeBundle(i:number, newBundleId:number){

    const currentBundles = [...data.offerData.bundles]

    axios.get(`http://localhost:4000/bundles/${newBundleId}`).then((res)=>{
      
      currentBundles[i] = res.data

      setData(prev=>({...prev, offerData: {...prev.offerData, bundles: [...currentBundles]}}))
      
    }).catch((err)=>{
      console.log(err)
    })
    
  }

  function deleteParameter(i:number){

    const currentParameters = [...data.offerData.parameters]

    currentParameters.splice(i,1)

    setData(prev=>({...prev, offerData: {...prev.offerData, parameters: [...currentParameters]}}))
    
  }

  function changeParameter(i:number, newData:string){

    const currentParameters = [...data.offerData.parameters]

    currentParameters[i] = newData

    setData(prev=>({...prev, offerData: {...prev.offerData, parameters: [...currentParameters]}}))
    
  }

  useEffect(()=>{
    if(newOrEdit === "edit"){
      getData()
    }
  },[])

  console.log(data)
  
  return (
    <Box pt="130px" w="1055px" display="flex" mx="auto">
      <Box display="flex" flexDir="column" w="450px">

        <Box mb="10px" color="#607B96" display="flex">
          <Text mr="10px" fontWeight="600">ID:</Text>
          <Text textTransform="capitalize">{data.id}</Text>
        </Box>

        <InputText label="Name" name="serviceName" biggerLabel type="text" value={data.serviceName} loading={inputsLoading} return={(dataInput)=>setData(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>

        <InputTextarea label="Description" name="serviceDescription" biggerLabel value={data.serviceDescription} loading={inputsLoading} sx={{minH: "100px"}} return={(dataInput)=>setData(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>

        <InputText label="Name Gridly Localization Key" biggerLabel name="nameGridlyLocalizationKey" type="text" value={data.offerData.nameGridlyLocalizationKey} loading={inputsLoading} return={(dataInput)=>setData(prev=>({...prev, offerData: {...prev.offerData, [dataInput.name]: dataInput.value}}))}/>

        <InputTextarea label="Description Gridly Localization Key" biggerLabel name="descGridlyLocalizationKey" value={data.offerData.descGridlyLocalizationKey} loading={inputsLoading} return={(dataInput)=>setData(prev=>({...prev, offerData: {...prev.offerData, [dataInput.name]: dataInput.value}}))}/>

        <InputText label="Background Name" biggerLabel name="backgroundName" type="text" value={data.offerData.backgroundName} loading={inputsLoading} return={(dataInput)=>setData(prev=>({...prev, offerData: {...prev.offerData, [dataInput.name]: dataInput.value}}))}/>

        <InputSelectText 
          label="Offer Type"
          biggerLabel 
          name="offerType"
          loading={inputsLoading} 
          placeholder="Select..." 
          value={data.offerType === 0 ? "Single Item" : data.offerType === 1 ? "Bundle" : ""}
          dropdownTexts={[[{id: 0, name: "Single Item"}, {id: 1, name: "Bundle"}]]} 
          sxDiv={{w: "100%"}}
          return={(dataInput)=>setData(prev=>({...prev, [dataInput.name]: Number(dataInput.value.id)}))}
        />

        <InputSelectText 
          label="Product Type"
          biggerLabel 
          name="productType"
          loading={inputsLoading} 
          placeholder="Select..." 
          value={data.offerData.productType === 1 ? "Consumable" : ""}
          dropdownTexts={[[{id: 0, name: "Nothing"}, {id: 1, name: "Consumable"}]]} 
          sxDiv={{w: "100%"}}
          return={(dataInput)=>setData(prev=>({...prev, offerData: {...prev.offerData, [dataInput.name]: Number(dataInput.value.id)}}))}
        />

        <InputSelectText 
          label="Product Key"
          biggerLabel 
          name="storeProductKey"
          loading={inputsLoading} 
          placeholder="Select..." 
          value={data.offerData.storeProductKey}
          dropdownTexts={[[{id: "def_materia_12500_001", name: "def_materia_12500_001"}, {id: "def_bundle3_001", name: "def_bundle3_001"}]]} 
          sxDiv={{w: "100%"}}
          return={(dataInput)=>setData(prev=>({...prev, offerData: {...prev.offerData, [dataInput.name]: dataInput.value.id}}))}
        />

      </Box>

      <Box display="flex" flexDir="column" w="450px" ml="auto">

        <Box display="flex" justifyContent="space-between">

          <InputNumber label="Priority Order" biggerLabel name="priorityOrder" value={data.priorityOrder} loading={inputsLoading} sxDiv={{w: "45%"}} return={(dataInput)=>setData(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>

          <InputNumber label="Unlock Level" biggerLabel name="unlockLevel" min={0} value={data.unlockLevel} loading={inputsLoading} sxDiv={{w: "45%"}} return={(dataInput)=>setData(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>

        </Box>

        <Box display="flex" flexWrap="wrap">

          <InputCheckbox biggerLabel topLabel="Active" name="isActive" value={data.isActive} sxDiv={{w: "50%"}} return={(dataInput)=>setData(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>
          
          <InputCheckbox biggerLabel topLabel="1 Time Purchase" name="isOneTimePurchase" value={data.isOneTimePurchase} sxDiv={{w: "50%"}} return={(dataInput)=>setData(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>

          <InputCheckbox biggerLabel topLabel="Visible On Start Level" name="isVisibleOnStartLevel" value={data.isVisibleOnStartLevel} sxDiv={{w: "50%"}} return={(dataInput)=>setData(prev=>({...prev, [dataInput.name]: dataInput.value}))}/>

          <InputCheckbox biggerLabel topLabel="Unique Store Key" name="isUniqueStoreKey" value={data.offerData.isUniqueStoreKey} sxDiv={{w: "50%"}} return={(dataInput)=>setData(prev=>({...prev, offerData: {...prev.offerData, [dataInput.name]: dataInput.value}}))}/>

          <InputCheckbox biggerLabel topLabel="Most Popular" name="isMostPopular" value={data.offerData.isMostPopular} sxDiv={{w: "50%"}} return={(dataInput)=>setData(prev=>({...prev, offerData: {...prev.offerData, [dataInput.name]: dataInput.value}}))}/>

          <InputCheckbox biggerLabel topLabel="Best Value" name="isBestValue" value={data.offerData.isBestValue} sxDiv={{w: "50%"}} return={(dataInput)=>setData(prev=>({...prev, offerData: {...prev.offerData, [dataInput.name]: dataInput.value}}))}/>

        </Box>

        <Box display="flex" flexDir="column" mb="10px">
          <Text mb="10px" color="#607B96" fontWeight="600">Bundles:</Text> 

          {
            data.offerData.bundles.length > 0 &&
              data.offerData.bundles.map((bundle,i)=>
                <Box key={i} display="flex" flexDir="row" mb="10px"
                  as={motion.div} initial={{top: "-20px", opacity: 0}} animate={{top: "0px", opacity: 1}} transition={`${i*0.1}s`}
                >
                  <InputSelectText 
                    name=""
                    loading={inputsLoading} 
                    placeholder="Select..." 
                    value={`Bundle ${bundle.id}`}
                    dropdownTexts={[[{id:100, name: "Bundle 100"}, {id:101, name: "Bundle 101"}, {id:102, name: "Bundle 102"}]]} 
                    sxDiv={{mr: "2.5%", w: "87.5%", mb: 0}}
                    return={(data)=>changeBundle(i,Number(data.value.id))}
                  />
                  <InputButtonIcon
                    icon="remove"
                    onClick={(e)=>deleteBundle(i)}
                    size="md"
                    sx={{w: "100%", height: "35px!important", aspectRatio: "auto", borderRadius: "8px"}}
                    sxDiv={{w: "10%", display: "flex", alignItems: "end"}}
                  />
                </Box>
              )
          }

          <InputButtonIcon
            icon="add"
            size="md"
            loading={buttonLoading.actionButtons}
            sx={{w: "100%", height: "35px!important", aspectRatio: "auto", borderRadius: "8px"}}
            sxDiv={{display: "flex", alignItems: "end"}}
            onClick={(e)=>addBundle()}
          />
        </Box>

        <Box display="flex" flexDir="column" mb="10px">
          <Text mb="10px" color="#607B96" fontWeight="600">Parameters:</Text> 

          {
            data.offerData.parameters.length > 0 &&
              data.offerData.parameters.map((parameter,i)=>
                <Box key={i} display="flex" flexDir="row" mb="10px"
                  as={motion.div} initial={{top: "-20px", opacity: 0}} animate={{top: "0px", opacity: 1}} transition={`${i*0.1}s`}
                >
                  <InputText name="" type="text" value={parameter} loading={inputsLoading} sxDiv={{m: "0 2.5% 0 0", w: "87.5%"}} 
                    return={(dataInput)=>changeParameter(i,dataInput.value)}
                  />
                  <InputButtonIcon
                    icon="remove"
                    onClick={()=>deleteParameter(i)}
                    size="md"
                    sx={{w: "100%", height: "35px!important", aspectRatio: "auto", borderRadius: "8px"}}
                    sxDiv={{w: "10%", display: "flex", alignItems: "end"}}
                  />
                </Box>
              )
          }

          <InputButtonIcon
            icon="add"
            size="md"
            loading={buttonLoading.actionButtons}
            sx={{w: "100%", height: "35px!important", aspectRatio: "auto", borderRadius: "8px"}}
            sxDiv={{display: "flex", alignItems: "end"}}
            onClick={()=>setData(prev=>({...prev, offerData: {...prev.offerData, parameters: [...prev.offerData.parameters, ""]}}))}
          />
        </Box>

        <Flex justifyContent="end">
          <InputButtonIcon icon="back" size="md" loading={buttonLoading.backButton} sx={{mr: "10px"}} onClick={()=>navigate("/in-app-purchases-editor/offers")}/>
          {
            newOrEdit === "edit" && <InputButtonIcon icon="remove" size="md" loading={buttonLoading.actionButtons} sx={{mr: "10px"}} onClick={()=>null}/>
          }
          <InputButtonIcon icon="save" size="md" loading={buttonLoading.actionButtons} onClick={()=> newOrEdit === "new" ? null : null}/>
        </Flex>
          
      </Box>

    </Box>
  )
}

export default Bundle