export default [
  {
    type: 1,
    name: "Blue Feather Ball"
  },
  {
    type: 2,
    name: "Yellow Feather Ball"
  },
  {
    type: 3,
    name: "Red Feather Ball"
  },
  {
    type: 4,
    name: "Purple Feather Ball"
  },
  {
    type: 5,
    name: "Green Feather Ball"
  },
]