import { useSelector, useDispatch } from "react-redux"
import { changeXSize, changeYSize, toggleCellAsEndOfTile, toggleCellAvailability } from "../../../../redux/levelSlice"
import InterfaceAllSlices from "../../../../typescript/interfaceAllSlices"

import {Box, Icon} from "@chakra-ui/react"
import {ImPlus} from "react-icons/im"

import InputSlider from "../../../Inputs/InputSlider"


const GridSizeAndPlayableFields = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  function onChangeXSize(val:number){
    const data = {
      xSize: val,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeXSize(data))
  }

  function onChangeYSize(val:number){
    const data = {
      ySize: val,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeYSize(data))
  }

  function onClickToggleCellAvailability({x,y}:{x:number,y:number}){
    const data = {
      x: Number(x),
      y: Number(y),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(toggleCellAvailability(data))
  }

  function onClickToggleCellAsEndOfTile({x,y}:{x:number,y:number}){
    const data = {
      x: Number(x),
      y: Number(y),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(toggleCellAsEndOfTile(data))
  }
  
  return (
    <Box pt="100px" mx="auto" width="300px" display="flex" flexDir="column">

      <Box m="0 auto 40px auto" w="95%">
        <InputSlider name="" label="Width" sxDiv={{w:"100%", m:"0 auto 20px auto"}} value={levelStates.xSize} min={3} max={8} return={(dataInput)=>onChangeXSize(dataInput.value)}/>
        <InputSlider name="" label="Height" sxDiv={{w:"100%", m:"0 auto"}} value={levelStates.ySize} min={3} max={9} return={(dataInput)=>onChangeYSize(dataInput.value)}/>
      </Box>
      
      <Box display="flex" flexDir="column" m="auto" w="fit-content" mx="auto">
        {
          [...Array(levelStates.ySize)].map((row,i)=>
            <Box key={i} display="flex">
              {
                levelStates.tileDataContainers.filter((cell)=>cell.column === i && cell.row < levelStates.xSize).map((cell,k)=>                    
                  <Box key={k} w="32px" h="32px" border="2px solid" borderColor={cell.blockerData[0]?.type !== 262144 ? "#4BB34B" : "#333"} borderRadius="6px" borderBottomLeftRadius={cell.isBottomEndTile === true ? "0px" : "6px"} borderBottomRightRadius={cell.isBottomEndTile === true ? "0px" : "6px"} borderBottomStyle={cell.isBottomEndTile === true ? "dotted" : "solid"} display="flex" alignItems="center" justifyContent="center" m="2px" cursor="pointer" transition="200ms" 
                    onContextMenu={(e)=>{e.preventDefault(), onClickToggleCellAsEndOfTile({x:cell.row, y:cell.column})}}
                    onClick={()=>onClickToggleCellAvailability({x:cell.row, y:cell.column})}
                  >
                    <Icon as={ImPlus} fontSize="14px" color={cell.blockerData[0]?.type !== 262144 ? "#4BB34B" : "#333"} sx={{transform: cell.blockerData[0]?.type !== 262144 === true ? "rotate(0deg)" : "rotate(-45deg)"}} transition="200ms"/>
                  </Box>
                )
              }
            </Box>
          ).sort((a,b) => a > b ? 1 : -1)
        }
      </Box>
    </Box>
  )
}

export default GridSizeAndPlayableFields